import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { StarIcon, MapPinIcon, ClockIcon, PhoneIcon, ChatBubbleLeftRightIcon, HeartIcon, ShieldCheckIcon, CurrencyDollarIcon, TagIcon, EnvelopeIcon, ExclamationTriangleIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { fetchAnuncioById, atualizarUsuario, buscarAnunciosRelacionados } from '../utils/airtable';
import { AuthContext } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const DetalhesAnuncio = () => {
  const { id } = useParams();
  const [anuncio, setAnuncio] = useState(null);
  const [anunciosRelacionados, setAnunciosRelacionados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingRelacionados, setLoadingRelacionados] = useState(true);
  const [activeImage, setActiveImage] = useState(0);
  const { user, updateUser } = useContext(AuthContext);

  useEffect(() => {
    const carregarAnuncio = async () => {
      try {
        console.log('Carregando anúncio com ID:', id);
        const dadosAnuncio = await fetchAnuncioById(id);
        console.log('Dados do anúncio:', dadosAnuncio);
        setAnuncio(dadosAnuncio);
        setLoading(false);
        
        // Carregar anúncios relacionados
        console.log('Buscando anúncios relacionados...');
        const relacionados = await buscarAnunciosRelacionados(dadosAnuncio);
        console.log('Anúncios relacionados:', relacionados);
        setAnunciosRelacionados(relacionados);
        setLoadingRelacionados(false);
      } catch (error) {
        console.error('Erro ao carregar anúncio:', error);
        toast.error('Erro ao carregar detalhes do anúncio');
        setLoading(false);
        setLoadingRelacionados(false);
      }
    };
    carregarAnuncio();
  }, [id]);

  const toggleFavorito = async () => {
    if (!user) {
      toast.error('Você precisa estar logado para favoritar anúncios.');
      return;
    }

    const novosFavoritos = user.favoritos?.includes(id)
      ? user.favoritos.filter(favId => favId !== id)
      : [...(user.favoritos || []), id];

    try {
      await atualizarUsuario(user.username, { favoritos: novosFavoritos });
      updateUser({ ...user, favoritos: novosFavoritos });
      toast.success(novosFavoritos.includes(id) ? 'Anúncio adicionado aos favoritos.' : 'Anúncio removido dos favoritos.');
    } catch (error) {
      console.error('Erro ao atualizar favoritos:', error);
      toast.error('Erro ao atualizar favoritos. Tente novamente.');
    }
  };

  const handleLigar = () => {
    if (anuncio && anuncio.vendedor && anuncio.vendedor.telefone) {
      window.location.href = `tel:${anuncio.vendedor.telefone}`;
    } else {
      toast.error('Número de telefone do vendedor não disponível');
    }
  };

  const handleEnviarEmail = () => {
    if (anuncio && anuncio.vendedor && anuncio.vendedor.email) {
      window.location.href = `mailto:${anuncio.vendedor.email}`;
    } else {
      toast.error('Endereço de e-mail do vendedor não disponível');
    }
  };

  const AnuncioSkeleton = () => (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="md:w-1/2">
          <Skeleton height={400} className="mb-4" />
          <div className="grid grid-cols-5 gap-2">
            {[...Array(5)].map((_, index) => (
              <Skeleton key={index} height={80} />
            ))}
          </div>
        </div>
        <div className="md:w-1/2">
          <Skeleton height={36} width={300} className="mb-2" />
          <Skeleton height={24} width={200} className="mb-4" />
          <Skeleton height={48} width={150} className="mb-4" />
          <Skeleton height={100} className="mb-4" />
          <Skeleton height={50} className="mb-4" />
          <Skeleton height={50} className="mb-4" />
          <Skeleton height={100} className="mb-4" />
          <Skeleton height={80} className="mb-4" />
          <Skeleton height={50} className="mb-4" />
        </div>
      </div>
      <div className="mt-8">
        <Skeleton height={36} width={200} className="mb-4" />
        <Skeleton count={3} height={20} className="mb-2" />
      </div>
    </div>
  );

  console.log('Renderizando - anuncio:', anuncio);
  console.log('Renderizando - anunciosRelacionados:', anunciosRelacionados);
  console.log('Renderizando - loading:', loading);
  console.log('Renderizando - loadingRelacionados:', loadingRelacionados);

  if (loading) return <AnuncioSkeleton />;
  if (!anuncio) return <p className="text-center">Anúncio não encontrado.</p>;

  const imagens = Array.isArray(anuncio.imagens) ? anuncio.imagens : [anuncio.imagem];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Coluna da esquerda - Imagens */}
        <div className="lg:w-1/2">
          <div className="w-full aspect-square relative mb-4">
            <img
              src={imagens[activeImage]}
              alt={anuncio.titulo}
              className="absolute top-0 left-0 w-full h-full object-contain rounded-lg shadow-md"
            />
          </div>
          <div className="grid grid-cols-5 gap-2">
            {imagens.map((imagem, index) => (
              <div
                key={index}
                className={`w-20 h-20 relative cursor-pointer ${
                  index === activeImage ? 'ring-2 ring-blue-500' : ''
                }`}
                onClick={() => setActiveImage(index)}
              >
                <img
                  src={imagem}
                  alt={`${anuncio.titulo} - Imagem ${index + 1}`}
                  className="absolute top-0 left-0 w-full h-full object-contain rounded-md"
                />
              </div>
            ))}
          </div>
        </div>

        {/* Coluna da direita - Detalhes do anúncio */}
        <div className="lg:w-1/2">
          <div className="flex items-center justify-between mb-2">
            <h1 className="text-3xl font-bold">{anuncio.titulo}</h1>
            {anuncio.destaque && (
              <div className="flex items-center bg-yellow-400 text-yellow-800 px-3 py-1 rounded-full text-sm font-semibold">
                <SparklesIcon className="w-5 h-5 mr-1" />
                Anúncio em Destaque
              </div>
            )}
          </div>
          <p className="text-xl text-gray-600 mb-4">{anuncio.marca} - {anuncio.modelo}</p>
          
          <div className="flex items-center mb-4">
            <CurrencyDollarIcon className="w-6 h-6 text-green-600 mr-2" />
            <p className="text-3xl font-bold text-green-600">R$ {anuncio.preco.toFixed(2)}</p>
          </div>

          <div className="flex items-center mb-4 text-sm text-gray-600">
            <MapPinIcon className="w-5 h-5 mr-2" />
            <p>{anuncio.localizacao}</p>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Características principais</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="font-semibold">Condição</p>
                <p>{anuncio.condicao}</p>
              </div>
              <div>
                <p className="font-semibold">Ano</p>
                <p>{anuncio.ano || 'N/A'}</p>
              </div>
              <div>
                <p className="font-semibold">Material</p>
                <p>{anuncio.material || 'N/A'}</p>
              </div>
              <div>
                <p className="font-semibold">Diâmetro</p>
                <p>{anuncio.diametro ? `${anuncio.diametro} mm` : 'N/A'}</p>
              </div>
              <div>
                <p className="font-semibold">Movimento</p>
                <p>{anuncio.movimento || 'N/A'}</p>
              </div>
              <div>
                <p className="font-semibold">Categoria</p>
                <p>{anuncio.categoria}</p>
              </div>
            </div>
          </div>

          {anuncio.caracteristicas && (
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2">Características adicionais</h2>
              <p>{anuncio.caracteristicas}</p>
            </div>
          )}

          <div className="flex items-center mb-4">
            <TagIcon className="w-5 h-5 mr-2 text-blue-500" />
            <p className="text-sm">
              {anuncio.frete_gratis ? 'Frete grátis' : 'Frete a calcular'}
            </p>
          </div>

          <div className="flex space-x-4 mb-6">
            <button 
              onClick={() => {/* Implementar lógica de compra */}}
              className="flex-1 bg-green-500 text-white py-3 rounded-full hover:bg-green-600 transition duration-300"
            >
              Comprar agora
            </button>
            <button 
              onClick={toggleFavorito}
              className={`flex items-center justify-center px-4 rounded-full transition duration-300 ${
                user?.favoritos?.includes(id) ? 'bg-red-500 text-white hover:bg-red-600' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              <HeartIcon className="w-6 h-6" />
            </button>
          </div>

          <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 mb-6 rounded-lg">
            <div className="flex items-center">
              <ExclamationTriangleIcon className="w-6 h-6 text-yellow-500 mr-2" />
              <h2 className="text-lg font-semibold text-yellow-700">Alerta de Segurança</h2>
            </div>
            <p className="mt-2 text-sm text-yellow-700">
              Proteja-se contra golpes! Nunca faça pagamentos antecipados ou transferências para contas desconhecidas. 
              Sempre verifique a identidade do vendedor e, se possível, realize a transação pessoalmente em um local seguro. 
              Desconfie de ofertas muito abaixo do valor de mercado. Em caso de suspeita, denuncie o anúncio.
            </p>
          </div>

          <div className="mb-6 bg-white p-4 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Informações do Vendedor</h2>
            <div className="flex items-center mb-4">
              {anuncio.vendedor?.profilePicture ? (
                <img 
                  src={anuncio.vendedor.profilePicture} 
                  alt={anuncio.vendedor.nome} 
                  className="w-16 h-16 rounded-full mr-4 object-cover"
                />
              ) : (
                <div className="w-16 h-16 rounded-full mr-4 bg-gray-300 flex items-center justify-center">
                  <span className="text-2xl text-gray-600">{anuncio.vendedor?.nome.charAt(0)}</span>
                </div>
              )}
              <div>
                <p className="font-medium text-lg">{anuncio.vendedor?.nome}</p>
                <p className="text-sm text-gray-600 flex items-center mt-1">
                  <MapPinIcon className="w-4 h-4 mr-1" />
                  {anuncio.localizacao}
                </p>
              </div>
            </div>
            <div className="space-y-2">
              {anuncio.vendedor?.telefone && (
                <p className="flex items-center">
                  <PhoneIcon className="w-5 h-5 mr-2 text-gray-600" />
                  {anuncio.vendedor.telefone}
                </p>
              )}
              {anuncio.vendedor?.email && (
                <p className="flex items-center">
                  <EnvelopeIcon className="w-5 h-5 mr-2 text-gray-600" />
                  {anuncio.vendedor.email}
                </p>
              )}
            </div>
            <div className="mt-4 flex space-x-4">
              {anuncio.vendedor?.instagram && (
                <a href={`https://instagram.com/${anuncio.vendedor.instagram}`} target="_blank" rel="noopener noreferrer" className="text-pink-600 hover:text-pink-700">
                  Instagram
                </a>
              )}
              {anuncio.vendedor?.facebook && (
                <a href={anuncio.vendedor.facebook} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700">
                  Facebook
                </a>
              )}
              {anuncio.vendedor?.whatsapp && (
                <a href={`https://wa.me/${anuncio.vendedor.whatsapp}`} target="_blank" rel="noopener noreferrer" className="text-green-600 hover:text-green-700">
                  WhatsApp
                </a>
              )}
            </div>
          </div>

          <button 
            onClick={handleEnviarEmail}
            className="w-full bg-blue-500 text-white py-3 rounded-full hover:bg-blue-600 transition duration-300 mb-6"
          >
            Contatar Vendedor
          </button>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">Descrição</h2>
        <p className="text-gray-700 whitespace-pre-line">{anuncio.descricao}</p>
      </div>

      {/* Seção de Anúncios Relacionados */}
      <div className="mt-12">
        <h2 className="text-2xl font-semibold mb-4">Anúncios Relacionados</h2>
        {loadingRelacionados ? (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="bg-gray-200 h-48 rounded-lg animate-pulse"></div>
            ))}
          </div>
        ) : anunciosRelacionados && anunciosRelacionados.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {anunciosRelacionados.map((anuncioRelacionado) => (
              <Link 
                key={anuncioRelacionado.id} 
                to={`/anuncio/${anuncioRelacionado.id}`}
                className="bg-white rounded-lg shadow-md hover:shadow-lg transition duration-300 overflow-hidden flex flex-col"
              >
                <div className="w-full h-48 relative overflow-hidden">
                  <img 
                    src={anuncioRelacionado.imagem} 
                    alt={anuncioRelacionado.titulo} 
                    className="object-cover w-48 h-48 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  />
                </div>
                <div className="p-4 flex-grow">
                  <h3 className="font-semibold text-lg mb-1 truncate">{anuncioRelacionado.titulo}</h3>
                  <p className="text-sm text-gray-600 mb-2 truncate">{anuncioRelacionado.marca}</p>
                  <p className="text-lg font-bold text-green-600">R$ {anuncioRelacionado.preco.toFixed(2)}</p>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <p className="text-gray-600">Nenhum anúncio relacionado encontrado.</p>
        )}
      </div>
    </div>
  );
};

export default DetalhesAnuncio;
