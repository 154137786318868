import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { buscarUsuarioPorUsername, atualizarUsuario } from '../utils/airtable';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { CameraIcon } from '@heroicons/react/24/solid';

const Perfil = () => {
  const { user, updateUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    bio: '',
    localizacao: '',
    dataNascimento: '',
    instagramUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    notificacaoEmail: false,
    notificacaoSMS: false,
    notificacaoPush: false
  });
  const [loading, setLoading] = useState(true);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageFile, setProfileImageFile] = useState(null);

  useEffect(() => {
    const carregarDadosUsuario = async () => {
      try {
        const dadosUsuario = await buscarUsuarioPorUsername(user.username);
        setFormData({
          nome: dadosUsuario.nome || '',
          email: dadosUsuario.email || '',
          telefone: dadosUsuario.telefone || '',
          bio: dadosUsuario.bio || '',
          localizacao: dadosUsuario.localizacao || '',
          dataNascimento: dadosUsuario.dataNascimento || '',
          instagramUrl: dadosUsuario.instagramUrl || '',
          facebookUrl: dadosUsuario.facebookUrl || '',
          twitterUrl: dadosUsuario.twitterUrl || '',
          notificacaoEmail: dadosUsuario.notificacaoEmail || false,
          notificacaoSMS: dadosUsuario.notificacaoSMS || false,
          notificacaoPush: dadosUsuario.notificacaoPush || false
        });
        setProfileImage(dadosUsuario.profilePicture || null);
      } catch (error) {
        console.error('Erro ao carregar dados do usuário:', error);
        toast.error('Erro ao carregar dados do usuário');
      } finally {
        setLoading(false);
      }
    };
    carregarDadosUsuario();
  }, [user.username]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setProfileImageFile(e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log('Submetendo formulário com identificador:', user.id || user.username);
      const dadosAtualizados = {
        ...formData
      };
      const usuarioAtualizado = await atualizarUsuario(user.id || user.username, dadosAtualizados, profileImageFile);
      updateUser(usuarioAtualizado);
      toast.success('Perfil atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
      toast.error('Erro ao atualizar perfil');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center mt-8">Carregando...</div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container mx-auto px-4 py-8"
    >
      <h2 className="text-3xl font-bold mb-6">Meu Perfil</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex items-center space-x-6">
          <div className="relative">
            <img
              src={profileImage || 'https://via.placeholder.com/150'}
              alt="Foto de perfil"
              className="w-32 h-32 rounded-full object-cover"
            />
            <label htmlFor="profile-image" className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full cursor-pointer">
              <CameraIcon className="w-5 h-5" />
            </label>
            <input
              type="file"
              id="profile-image"
              accept="image/*"
              className="hidden"
              onChange={handleImageChange}
            />
          </div>
          <div>
            <h3 className="text-xl font-semibold">{formData.nome}</h3>
            <p className="text-gray-600">@{user.username}</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="nome" className="block text-sm font-medium text-gray-700">Nome</label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="telefone" className="block text-sm font-medium text-gray-700">Telefone</label>
            <input
              type="tel"
              id="telefone"
              name="telefone"
              value={formData.telefone}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="localizacao" className="block text-sm font-medium text-gray-700">Localização</label>
            <input
              type="text"
              id="localizacao"
              name="localizacao"
              value={formData.localizacao}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="dataNascimento" className="block text-sm font-medium text-gray-700">Data de Nascimento</label>
            <input
              type="date"
              id="dataNascimento"
              name="dataNascimento"
              value={formData.dataNascimento}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
        </div>

        <div>
          <label htmlFor="bio" className="block text-sm font-medium text-gray-700">Biografia</label>
          <textarea
            id="bio"
            name="bio"
            rows="4"
            value={formData.bio}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          ></textarea>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-2">Redes Sociais</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label htmlFor="instagramUrl" className="block text-sm font-medium text-gray-700">Instagram</label>
              <input
                type="text"
                id="instagramUrl"
                name="instagramUrl"
                value={formData.instagramUrl}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label htmlFor="facebookUrl" className="block text-sm font-medium text-gray-700">Facebook</label>
              <input
                type="text"
                id="facebookUrl"
                name="facebookUrl"
                value={formData.facebookUrl}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label htmlFor="twitterUrl" className="block text-sm font-medium text-gray-700">Twitter</label>
              <input
                type="text"
                id="twitterUrl"
                name="twitterUrl"
                value={formData.twitterUrl}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-2">Preferências de Notificação</h3>
          <div className="space-y-2">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="notificacaoEmail"
                name="notificacaoEmail"
                checked={formData.notificacaoEmail}
                onChange={handleChange}
                className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
              <label htmlFor="notificacaoEmail" className="ml-2 block text-sm text-gray-900">
                Receber notificações por email
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="notificacaoSMS"
                name="notificacaoSMS"
                checked={formData.notificacaoSMS}
                onChange={handleChange}
                className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
              <label htmlFor="notificacaoSMS" className="ml-2 block text-sm text-gray-900">
                Receber notificações por SMS
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="notificacaoPush"
                name="notificacaoPush"
                checked={formData.notificacaoPush}
                onChange={handleChange}
                className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
              <label htmlFor="notificacaoPush" className="ml-2 block text-sm text-gray-900">
                Receber notificações push
              </label>
            </div>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={loading}
          >
            {loading ? 'Atualizando...' : 'Atualizar Perfil'}
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default Perfil;
