import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { motion } from 'framer-motion';
import { ReactComponent as Logo } from '../logotimespot.svg';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

// Função de debug
const debug = (message) => {
  console.log(`[DEBUG] ${new Date().toISOString()}: ${message}`);
};

const OAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loginWithGoogle } = useContext(AuthContext);
  const [authState, setAuthState] = useState({ status: 'loading', message: 'Iniciando autenticação...' });

  useEffect(() => {
    const handleCallback = async () => {
      debug(`URL completa do callback: ${window.location.href}`);
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');
      const error = urlParams.get('error');

      debug(`Callback iniciado. Código: ${code}, Erro: ${error}`);

      if (error) {
        debug(`Erro recebido: ${error}`);
        setAuthState({
          status: 'error',
          message: `Erro na autenticação: ${error}`
        });
        return;
      }

      if (!code) {
        debug('Código de autorização não encontrado');
        setAuthState({
          status: 'error',
          message: 'Código de autorização não encontrado'
        });
        return;
      }

      try {
        setAuthState({
          status: 'loading',
          message: 'Autenticando com o Google...'
        });
        
        debug('Chamando loginWithGoogle');
        const result = await loginWithGoogle(code);
        debug(`Resultado do loginWithGoogle: ${JSON.stringify(result)}`);
        
        if (result.success) {
          setAuthState({
            status: 'success',
            message: result.isNewUser ? 'Conta criada com sucesso!' : 'Login realizado com sucesso!'
          });
          setTimeout(() => {
            const baseUrl = process.env.NODE_ENV === 'production'
              ? 'https://timespot.com.br'
              : 'http://localhost:3000';
            debug(`Redirecionando para: ${result.isNewUser ? '/completar-cadastro' : '/'}`);
            navigate(result.isNewUser ? '/completar-cadastro' : '/');
          }, 2000);
        } else {
          debug('Login falhou, mas sem erro específico');
          setAuthState({
            status: 'error',
            message: 'Falha na autenticação. Por favor, tente novamente.'
          });
        }
      } catch (error) {
        debug(`Erro durante o callback do OAuth: ${error.message}`);
        setAuthState({
          status: 'error',
          message: 'Erro durante a autenticação. Por favor, tente novamente.'
        });
      }
    };

    handleCallback();
  }, [navigate, location, loginWithGoogle]);

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  const iconVariants = {
    hidden: { scale: 0 },
    visible: { scale: 1, transition: { type: 'spring', stiffness: 200, damping: 10 } }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-md w-full space-y-8 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-10 rounded-xl shadow-2xl"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="flex justify-center">
          <Logo className="h-20 w-auto text-white" />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
          {authState.status === 'success' ? 'Autenticação Concluída' : 'Processando autenticação'}
        </h2>
        <div className="mt-8 space-y-6">
          <motion.div
            className="flex justify-center items-center"
            variants={iconVariants}
            initial="hidden"
            animate="visible"
          >
            {authState.status === 'loading' ? (
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
            ) : (
              <CheckCircleIcon className="h-16 w-16 text-green-500" />
            )}
          </motion.div>
          <p className="text-center text-xl text-white">{authState.message}</p>
        </div>
      </motion.div>
    </div>
  );
};

export default OAuthCallback;
