import React, { useState } from 'react';
import { UserIcon, EnvelopeIcon, PencilSquareIcon, PaperAirplaneIcon, ChatBubbleLeftRightIcon, QuestionMarkCircleIcon, ShieldExclamationIcon } from '@heroicons/react/24/outline';

const Contato = () => {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    assunto: '',
    mensagem: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Lógica para enviar o formulário
    console.log('Formulário enviado:', formData);
    // Aqui você adicionaria a lógica para enviar o formulário para seu backend
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-blue-600">Entre em Contato</h1>

      <div className="grid md:grid-cols-2 gap-12">
        <div>
          <h2 className="text-2xl font-semibold mb-4">Como Podemos Ajudar?</h2>
          <p className="text-gray-700 mb-6">
            Estamos aqui para apoiar nossa comunidade de entusiastas de relógios. 
            Selecione o motivo do seu contato e entraremos em contato o mais breve possível.
          </p>

          <div className="space-y-4">
            <div className="flex items-start">
              <ChatBubbleLeftRightIcon className="w-6 h-6 text-blue-500 mr-3 mt-1" />
              <div>
                <h3 className="font-semibold">Suporte à Comunidade</h3>
                <p className="text-sm text-gray-600">Dúvidas sobre interações, negociações ou uso da plataforma.</p>
              </div>
            </div>
            <div className="flex items-start">
              <QuestionMarkCircleIcon className="w-6 h-6 text-blue-500 mr-3 mt-1" />
              <div>
                <h3 className="font-semibold">Perguntas Gerais</h3>
                <p className="text-sm text-gray-600">Informações sobre o TimeSpot, funcionalidades ou eventos.</p>
              </div>
            </div>
            <div className="flex items-start">
              <ShieldExclamationIcon className="w-6 h-6 text-blue-500 mr-3 mt-1" />
              <div>
                <h3 className="font-semibold">Reportar Problema</h3>
                <p className="text-sm text-gray-600">Comunicar violações das diretrizes da comunidade ou problemas técnicos.</p>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="nome" className="block text-sm font-medium text-gray-700 mb-1">Seu Nome</label>
            <div className="flex items-center">
              <UserIcon className="w-5 h-5 text-gray-400 mr-2" />
              <input
                type="text"
                id="nome"
                name="nome"
                value={formData.nome}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Seu E-mail</label>
            <div className="flex items-center">
              <EnvelopeIcon className="w-5 h-5 text-gray-400 mr-2" />
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="assunto" className="block text-sm font-medium text-gray-700 mb-1">Assunto</label>
            <div className="flex items-center">
              <PencilSquareIcon className="w-5 h-5 text-gray-400 mr-2" />
              <select
                id="assunto"
                name="assunto"
                value={formData.assunto}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Selecione um assunto</option>
                <option value="suporte">Suporte à Comunidade</option>
                <option value="pergunta">Pergunta Geral</option>
                <option value="problema">Reportar Problema</option>
              </select>
            </div>
          </div>
          <div>
            <label htmlFor="mensagem" className="block text-sm font-medium text-gray-700 mb-1">Sua Mensagem</label>
            <textarea
              id="mensagem"
              name="mensagem"
              value={formData.mensagem}
              onChange={handleChange}
              rows="4"
              className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center"
          >
            <PaperAirplaneIcon className="w-5 h-5 mr-2" />
            Enviar Mensagem
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contato;
