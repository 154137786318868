import React, { useContext, useState, useRef, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import { MagnifyingGlassIcon, ChevronDownIcon, UserCircleIcon, ShoppingBagIcon, ChatBubbleLeftRightIcon, StarIcon, HeartIcon, TagIcon, ClipboardDocumentListIcon, CurrencyDollarIcon, ChartBarIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Login from './Login';
import Home from './screens/Home';
import Anuncios from './screens/Anuncios';
import Sobre from './screens/Sobre';
import Contato from './screens/Contato';
import CriarAnuncio from './screens/CriarAnuncio';
import Admin from './screens/Admin';
import DetalhesAnuncio from './screens/DetalhesAnuncio';
import ListagemCategoria from './screens/ListagemCategoria';
import PerfilUsuario from './screens/Perfil';
import MeusAnuncios from './screens/MeusAnuncios';
import EditarAnuncio from './screens/EditarAnuncio';
import Favoritos from './screens/Favoritos';
import logoTimeSpot from './logotimespot.svg';
import Footer from './components/Footer';

// Adicione um novo componente para lidar com o callback do OAuth
import OAuthCallback from './components/OAuthCallback';
import CompletarCadastro from './components/CompletarCadastro';
import RotaProtegida from './components/RotaProtegida';

const categorias = [
  { nome: 'Luxo', slug: 'luxo' },
  { nome: 'Esportivo', slug: 'esportivo' },
  { nome: 'Vintage', slug: 'vintage' },
  { nome: 'Smartwatch', slug: 'smartwatch' },
  { nome: 'Aviador', slug: 'aviador' },
  { nome: 'Bolso', slug: 'bolso' },
  { nome: 'Grife', slug: 'grife' },
  { nome: 'Mergulho', slug: 'mergulho' },
  { nome: 'Ecológico', slug: 'ecologico' },
  { nome: 'Militar', slug: 'militar' },
  { nome: 'Minimalista', slug: 'minimalista' },
  { nome: 'Skeleton', slug: 'skeleton' }
];

const TimeSpot = () => {
  const { user, logout, loading, updateUserData } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);
  const [mobileMenuState, setMobileMenuState] = useState({
    isOpen: false,
    isCategoryOpen: false
  });
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const categoryMenuRef = useRef(null);

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/anuncios?search=${searchTerm}`);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleCategoryMenu = () => {
    setIsCategoryMenuOpen(!isCategoryMenuOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuState(prev => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const toggleMobileCategoryMenu = () => {
    setMobileMenuState(prev => ({ ...prev, isCategoryOpen: !prev.isCategoryOpen }));
  };

  const handleMobileCategoryClick = (slug) => {
    navigate(`/categoria/${slug}`);
    setMobileMenuState({ isOpen: false, isCategoryOpen: false });
  };

  useEffect(() => {
    let isMounted = true;
    const fetchUserData = async () => {
      if (user && !loading && isMounted) {
        await updateUserData(user.username);
      }
    };

    fetchUserData();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
      if (categoryMenuRef.current && !categoryMenuRef.current.contains(event.target)) {
        setIsCategoryMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Renderiza o cabeçalho e a navegação apenas se não estiver na página de login */}
      {window.location.pathname !== '/login' && (
        <>
          <div className="sticky top-0 z-50 bg-white shadow-md">
            <header className="bg-black text-white">
              <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <Link to="/" className="flex items-center">
                  <img src={logoTimeSpot} alt="TimeSpot" className="h-8 w-auto" />
                </Link>
                <form onSubmit={handleSearch} className="flex-grow mx-4">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Buscar..."
                      className="w-full px-4 py-2 rounded-full text-black"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button type="submit" className="absolute right-0 top-0 mt-2 mr-4">
                      <MagnifyingGlassIcon className="h-6 w-6 text-gray-400" />
                    </button>
                  </div>
                </form>
                <div className="flex items-center space-x-4">
                  {loading ? (
                    <div className="w-8 h-8 rounded-full bg-gray-300 animate-pulse"></div>
                  ) : user ? (
                    <div className="relative" ref={menuRef}>
                      <button 
                        onClick={toggleMenu} 
                        className="flex items-center bg-gray-800 hover:bg-gray-700 rounded-full py-1 px-2 transition duration-150 ease-in-out"
                      >
                        {user.profilePicture ? (
                          <img 
                            src={user.profilePicture}
                            alt="Foto de perfil" 
                            className="w-8 h-8 rounded-full mr-2 object-cover"
                          />
                        ) : (
                          <UserCircleIcon className="w-8 h-8 text-gray-300 mr-2" />
                        )}
                        <span className="text-sm font-medium text-white mr-1 hidden sm:inline">{user.nome}</span>
                        <ChevronDownIcon className="h-4 w-4 text-gray-300" />
                      </button>
                      {isMenuOpen && (
                        <div className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-lg z-10">
                          <Link
                            to="/perfil"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <UserCircleIcon className="w-5 h-5 mr-2" />
                            Meu Perfil
                          </Link>
                          <Link
                            to="/compras"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <ShoppingBagIcon className="w-5 h-5 mr-2" />
                            Compras
                          </Link>
                          <Link
                            to="/perguntas"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <ChatBubbleLeftRightIcon className="w-5 h-5 mr-2" />
                            Perguntas
                          </Link>
                          <Link
                            to="/opinioes"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <StarIcon className="w-5 h-5 mr-2" />
                            Opiniões
                          </Link>
                          <Link
                            to="/favoritos"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <HeartIcon className="w-5 h-5 mr-2" />
                            Favoritos
                          </Link>
                          <Link
                            to="/criar-anuncio"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <TagIcon className="w-5 h-5 mr-2" />
                            Criar Anúncio
                          </Link>
                          <Link
                            to="/meus-anuncios"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <ClipboardDocumentListIcon className="w-5 h-5 mr-2" />
                            Meus Anúncios
                          </Link>
                          <Link
                            to="/vendas"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <CurrencyDollarIcon className="w-5 h-5 mr-2" />
                            Vendas
                          </Link>
                          <Link
                            to="/reputacao"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <ChartBarIcon className="w-5 h-5 mr-2" />
                            Reputação
                          </Link>
                          <hr className="my-1" />
                          <button
                            onClick={() => {
                              handleLogout();
                              setIsMenuOpen(false);
                            }}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Sair
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link to="/login" className="hover:underline">Login</Link>
                  )}
                </div>
              </div>
            </header>

            <nav className="bg-gray-800 text-white relative">
              <div className="container mx-auto px-4">
                <div className="flex justify-between items-center py-2">
                  <button 
                    className="md:hidden"
                    onClick={toggleMobileMenu}
                  >
                    {mobileMenuState.isOpen ? (
                      <XMarkIcon className="h-6 w-6" />
                    ) : (
                      <Bars3Icon className="h-6 w-6" />
                    )}
                  </button>
                  <div className="hidden md:flex md:space-x-6">
                    <Link to="/" className="hover:underline">Home</Link>
                    <div className="relative" ref={categoryMenuRef}>
                      <button 
                        onClick={toggleCategoryMenu} 
                        className="hover:underline flex items-center"
                      >
                        Categorias
                        <ChevronDownIcon className="h-4 w-4 ml-1" />
                      </button>
                      {isCategoryMenuOpen && (
                        <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                          {categorias.map((categoria) => (
                            <Link
                              key={categoria.slug}
                              to={`/categoria/${categoria.slug}`}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              onClick={() => setIsCategoryMenuOpen(false)}
                            >
                              {categoria.nome}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                    <Link to="/anuncios" className="hover:underline">Anúncios</Link>
                    <Link to="/sobre" className="hover:underline">Sobre</Link>
                    <Link to="/contato" className="hover:underline">Contato</Link>
                  </div>
                </div>
              </div>
              {mobileMenuState.isOpen && (
                <div className="md:hidden absolute top-full left-0 right-0 bg-gray-800 z-50">
                  <div className="flex flex-col space-y-2 p-4">
                    <Link to="/" className="hover:underline" onClick={() => setMobileMenuState({ isOpen: false, isCategoryOpen: false })}>Home</Link>
                    <div>
                      <button 
                        onClick={toggleMobileCategoryMenu}
                        className="hover:underline flex items-center justify-between w-full text-left"
                      >
                        Categorias
                        <ChevronDownIcon className={`h-4 w-4 ml-1 transition-transform ${mobileMenuState.isCategoryOpen ? 'transform rotate-180' : ''}`} />
                      </button>
                      {mobileMenuState.isCategoryOpen && (
                        <div className="pl-4 mt-2 space-y-2">
                          {categorias.map((categoria) => (
                            <button
                              key={categoria.slug}
                              className="block py-1 text-sm hover:underline text-left w-full"
                              onClick={() => handleMobileCategoryClick(categoria.slug)}
                            >
                              {categoria.nome}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                    <Link to="/anuncios" className="hover:underline" onClick={() => setMobileMenuState({ isOpen: false, isCategoryOpen: false })}>Anúncios</Link>
                    <Link to="/sobre" className="hover:underline" onClick={() => setMobileMenuState({ isOpen: false, isCategoryOpen: false })}>Sobre</Link>
                    <Link to="/contato" className="hover:underline" onClick={() => setMobileMenuState({ isOpen: false, isCategoryOpen: false })}>Contato</Link>
                  </div>
                </div>
              )}
            </nav>
          </div>
        </>
      )}
      
      <main className={`flex-grow ${window.location.pathname === '/login' ? 'h-screen' : ''}`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/anuncios" element={<Anuncios />} />
          <Route path="/sobre" element={<Sobre />} />
          <Route path="/contato" element={<Contato />} />
          <Route path="/criar-anuncio" element={<CriarAnuncio />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/anuncio/:id" element={<DetalhesAnuncio />} />
          <Route path="/categoria/:categoria" element={<ListagemCategoria />} />
          <Route path="/perfil" element={
            <RotaProtegida>
              <PerfilUsuario />
            </RotaProtegida>
          } />
          <Route path="/meus-anuncios" element={<MeusAnuncios />} />
          <Route path="/editar-anuncio/:id" element={<EditarAnuncio />} />
          <Route path="/favoritos" element={<Favoritos />} />
          
          {/* Adicione uma nova rota para lidar com o callback do OAuth */}
          <Route path="/auth/google" element={<OAuthCallback />} />
          <Route path="/completar-cadastro" element={<CompletarCadastro />} />
        </Routes>
      </main>

      {/* Renderiza o rodapé apenas se não estiver na página de login */}
      {window.location.pathname !== '/login' && <Footer />}
    </div>
  );
};

export default TimeSpot;