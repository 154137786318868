import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { criarUsuario, buscarUsuarioPorUsername, atualizarUsuario } from '../utils/airtable';
import axios from 'axios';

// Função de debug
const debug = (message) => {
  console.log(`[DEBUG] ${new Date().toISOString()}: ${message}`);
};

export const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  register: () => {},
  loginWithGoogle: () => {},
  isNewUser: false,
  setIsNewUser: () => {},
  loading: false,
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const isCreatingUser = useRef(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  const loginWithGoogle = useCallback(async (code) => {
    if (isCreatingUser.current) {
      debug('Já está criando um usuário, ignorando chamada duplicada');
      return { success: false, isNewUser: false };
    }
    isCreatingUser.current = true;
    setLoading(true);
    try {
      debug('Iniciando troca de código por token');
      debug(`Código recebido: ${code}`);
      
      const data = new URLSearchParams({
        code,
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
        redirect_uri: `${window.location.origin}/auth/google`,
        grant_type: 'authorization_code'
      });

      debug(`URL de redirecionamento: ${window.location.origin}/auth/google`);
      debug(`Client ID: ${process.env.REACT_APP_GOOGLE_CLIENT_ID}`);

      const tokenResponse = await axios.post('https://oauth2.googleapis.com/token', data.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      debug('Token recebido com sucesso');
      const accessToken = tokenResponse.data.access_token;

      const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: { Authorization: `Bearer ${accessToken}` }
      });

      const googleUserInfo = userInfoResponse.data;
      debug(`Informações do usuário recebidas: ${JSON.stringify(googleUserInfo)}`);

      debug(`Buscando usuário no Airtable: ${googleUserInfo.email}`);
      let user = await buscarUsuarioPorUsername(googleUserInfo.email);
      
      let isNewUserFlag = false;
      if (!user) {
        debug('Usuário não encontrado, criando novo usuário');
        user = await criarUsuario({
          username: googleUserInfo.email,
          email: googleUserInfo.email,
          nome: googleUserInfo.name,
          profilePicture: googleUserInfo.picture,
        });
        isNewUserFlag = true;
        debug('Novo usuário criado, isNewUser definido como true');
      } else {
        debug('Usuário existente encontrado');
      }

      setUser(user);
      setIsNewUser(isNewUserFlag);
      localStorage.setItem('user', JSON.stringify(user));
      debug(`Estado final - isNewUser: ${isNewUserFlag}`);
      setLoading(false);
      isCreatingUser.current = false;
      return { success: true, isNewUser: isNewUserFlag };
    } catch (error) {
      debug(`Erro detalhado no login com Google: ${error.message}`);
      if (error.response) {
        debug(`Resposta de erro: ${JSON.stringify(error.response.data)}`);
      }
      setLoading(false);
      isCreatingUser.current = false;
      return { success: false, isNewUser: false };
    }
  }, []);

  const login = useCallback(async (username, password) => {
    setLoading(true);
    try {
      const user = await buscarUsuarioPorUsername(username);
      if (user && user.password === password) {
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
        setLoading(false);
        return true;
      }
      setLoading(false);
      return false;
    } catch (error) {
      console.error('Erro durante o login:', error);
      setLoading(false);
      return false;
    }
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    localStorage.removeItem('user');
  }, []);

  const register = useCallback(async (username, password, email) => {
    setLoading(true);
    try {
      const newUser = await criarUsuario({ username, password, email });
      if (newUser) {
        setUser(newUser);
        localStorage.setItem('user', JSON.stringify(newUser));
        setLoading(false);
        return true;
      }
      setLoading(false);
      return false;
    } catch (error) {
      console.error('Erro durante o registro:', error);
      setLoading(false);
      return false;
    }
  }, []);

  const updateUser = useCallback(async (dadosAtualizados) => {
    if (!user || !user.id) {
      console.error('Usuário não encontrado ou sem ID');
      return;
    }
    try {
      console.log('Iniciando atualização do usuário:', user.id, dadosAtualizados);
      const updatedUser = await atualizarUsuario(user.id, dadosAtualizados);
      if (updatedUser) {
        setUser(updatedUser);
        localStorage.setItem('user', JSON.stringify(updatedUser));
        console.log('Usuário atualizado com sucesso:', updatedUser);
      }
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      throw error;
    }
  }, [user]);

  const value = {
    user,
    login,
    logout,
    register,
    loginWithGoogle,
    isNewUser,
    setIsNewUser,
    loading,
    updateUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};