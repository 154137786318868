import React from 'react';

const TermosDeUsoModal = ({ isOpen, onClose, onAccept }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Termos de Uso e Serviço</h2>
        <div className="mb-6">
          <p>Aqui vão os seus termos de uso e serviço. Por exemplo:</p>
          <ul className="list-disc pl-5 mt-2">
            <li>Ao usar nosso serviço, você concorda em não publicar conteúdo ilegal ou ofensivo.</li>
            <li>Você é responsável por manter a segurança de sua conta.</li>
            <li>Nós nos reservamos o direito de encerrar contas que violem nossos termos.</li>
            <li>Seus dados pessoais serão tratados de acordo com nossa política de privacidade.</li>
          </ul>
          {/* Adicione mais termos conforme necessário */}
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Fechar
          </button>
          <button
            onClick={onAccept}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Aceitar
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermosDeUsoModal;
