import React from 'react';
import { gerarItensTeste } from '../utils/airtable';

const Admin = () => {
  const handleGerarItensTeste = async () => {
    try {
      await gerarItensTeste();
      alert('Itens de teste gerados com sucesso!');
    } catch (error) {
      console.error('Erro ao gerar itens de teste:', error);
      alert('Erro ao gerar itens de teste. Verifique o console para mais detalhes.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-6">Painel de Administração</h2>
      <button
        onClick={handleGerarItensTeste}
        className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300"
      >
        Gerar Itens de Teste
      </button>
    </div>
  );
};

export default Admin;
