import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { fetchAnuncios, excluirAnuncio } from '../utils/airtable';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';

const MeusAnuncios = () => {
  const { user } = useContext(AuthContext);
  const [anuncios, setAnuncios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalAberto, setModalAberto] = useState(false);
  const [anuncioParaExcluir, setAnuncioParaExcluir] = useState(null);

  useEffect(() => {
    carregarAnuncios();
  }, [user]);

  const carregarAnuncios = async () => {
    if (user) {
      try {
        setLoading(true);
        const todosAnuncios = await fetchAnuncios();
        const meusAnuncios = todosAnuncios.filter(anuncio => anuncio.usuario && anuncio.usuario[0] === user.id);
        setAnuncios(meusAnuncios);
      } catch (error) {
        console.error('Erro ao carregar anúncios:', error);
        toast.error('Erro ao carregar anúncios. Tente novamente.');
      } finally {
        setLoading(false);
      }
    }
  };

  const abrirModalExclusao = (anuncio) => {
    setAnuncioParaExcluir(anuncio);
    setModalAberto(true);
  };

  const fecharModal = () => {
    setModalAberto(false);
    setAnuncioParaExcluir(null);
  };

  const confirmarExclusao = async () => {
    if (anuncioParaExcluir) {
      try {
        await excluirAnuncio(anuncioParaExcluir.id);
        setAnuncios(anuncios.filter(a => a.id !== anuncioParaExcluir.id));
        toast.success('Anúncio excluído com sucesso!');
      } catch (error) {
        console.error('Erro ao excluir anúncio:', error);
        toast.error('Erro ao excluir anúncio. Tente novamente.');
      } finally {
        fecharModal();
      }
    }
  };

  if (loading) {
    return <p className="text-center py-8">Carregando seus anúncios...</p>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6">Meus Anúncios</h2>
      {anuncios.length === 0 ? (
        <p className="text-center text-gray-500">Você ainda não tem anúncios.</p>
      ) : (
        <ul className="space-y-4">
          {anuncios.map((anuncio) => (
            <li key={anuncio.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="flex items-center p-4">
                <img src={anuncio.imagem} alt={anuncio.titulo} className="w-24 h-24 object-cover rounded-md mr-4" />
                <div className="flex-grow">
                  <h3 className="text-lg font-semibold">{anuncio.titulo}</h3>
                  <p className="text-gray-600 text-sm mb-2">{anuncio.descricao.substring(0, 100)}...</p>
                  <p className="text-xl font-bold text-green-600">R$ {anuncio.preco.toFixed(2)}</p>
                </div>
                <div className="flex flex-col space-y-2">
                  <Link to={`/editar-anuncio/${anuncio.id}`} className="flex items-center text-blue-600 hover:text-blue-800">
                    <PencilIcon className="w-5 h-5 mr-1" />
                    Editar
                  </Link>
                  <button onClick={() => abrirModalExclusao(anuncio)} className="flex items-center text-red-600 hover:text-red-800">
                    <TrashIcon className="w-5 h-5 mr-1" />
                    Excluir
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="mt-8 text-center">
        <Link to="/criar-anuncio" className="bg-blue-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-blue-700 transition duration-300">
          Criar Novo Anúncio
        </Link>
      </div>

      {modalAberto && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="text-lg font-semibold mb-4">Confirmar exclusão</h3>
            <p className="mb-4">Tem certeza que deseja excluir o anúncio "{anuncioParaExcluir?.titulo}"?</p>
            <div className="flex justify-end space-x-4">
              <button onClick={fecharModal} className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300">
                Cancelar
              </button>
              <button onClick={confirmarExclusao} className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700">
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeusAnuncios;
