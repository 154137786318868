import React from 'react';
import { Link } from 'react-router-dom';
import { UserGroupIcon, ChatBubbleLeftRightIcon, ScaleIcon, ShieldCheckIcon, ClockIcon } from '@heroicons/react/24/outline';

const Sobre = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold mb-8 text-blue-600">Sobre o TimeSpot</h1>
            
            <section className="mb-12">
                <h2 className="text-3xl font-semibold mb-4">Nossa Comunidade</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                    O TimeSpot é uma comunidade vibrante dedicada a entusiastas de relógios. Aqui, colecionadores, 
                    vendedores e compradores se reúnem para compartilhar sua paixão, trocar conhecimentos e 
                    realizar transações de relógios de forma segura e transparente.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-3xl font-semibold mb-6">O que nos torna únicos</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="flex items-start">
                        <UserGroupIcon className="w-8 h-8 text-blue-500 mr-4" />
                        <div>
                            <h3 className="text-xl font-semibold mb-2">Comunidade Engajada</h3>
                            <p className="text-gray-700">Conecte-se com outros entusiastas, troque experiências e aprenda sobre relógios.</p>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <ChatBubbleLeftRightIcon className="w-8 h-8 text-blue-500 mr-4" />
                        <div>
                            <h3 className="text-xl font-semibold mb-2">Negociações Diretas</h3>
                            <p className="text-gray-700">Comunique-se diretamente com vendedores e compradores para acordos personalizados.</p>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <ScaleIcon className="w-8 h-8 text-blue-500 mr-4" />
                        <div>
                            <h3 className="text-xl font-semibold mb-2">Flexibilidade nas Transações</h3>
                            <p className="text-gray-700">Venda, compre ou troque relógios de acordo com suas preferências.</p>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <ShieldCheckIcon className="w-8 h-8 text-blue-500 mr-4" />
                        <div>
                            <h3 className="text-xl font-semibold mb-2">Ambiente Seguro</h3>
                            <p className="text-gray-700">Sistema de reputação e diretrizes da comunidade para transações confiáveis.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-12">
                <h2 className="text-3xl font-semibold mb-6">Como Participar</h2>
                <ol className="list-decimal list-inside space-y-4 text-lg text-gray-700">
                    <li><strong>Crie seu perfil:</strong> Registre-se e apresente-se à comunidade.</li>
                    <li><strong>Explore anúncios:</strong> Navegue por relógios disponíveis para venda ou troca.</li>
                    <li><strong>Interaja:</strong> Faça perguntas, ofereça ou negocie diretamente com outros membros.</li>
                    <li><strong>Anuncie:</strong> Compartilhe os relógios que você deseja vender ou trocar.</li>
                    <li><strong>Construa sua reputação:</strong> Realize transações bem-sucedidas e receba avaliações positivas.</li>
                </ol>
            </section>

            <section className="mb-12">
                <h2 className="text-3xl font-semibold mb-6">Junte-se à Nossa Comunidade</h2>
                <div className="flex flex-col sm:flex-row gap-4">
                    <Link to="/cadastro" className="bg-blue-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-blue-700 transition duration-300">Criar Conta</Link>
                    <Link to="/anuncios" className="bg-gray-200 text-gray-800 px-6 py-3 rounded-lg text-lg font-semibold hover:bg-gray-300 transition duration-300">Ver Anúncios</Link>
                </div>
            </section>

            <section>
                <h2 className="text-3xl font-semibold mb-6">Precisa de Ajuda?</h2>
                <p className="text-lg text-gray-700 mb-4">
                    Se você tiver dúvidas sobre como participar da comunidade TimeSpot ou precisar de assistência, 
                    estamos aqui para ajudar.
                </p>
                <Link to="/contato" className="flex items-center text-blue-600 hover:text-blue-800 transition duration-300">
                    <ClockIcon className="w-6 h-6 mr-2" />
                    <span className="text-lg font-semibold">Entre em Contato</span>
                </Link>
            </section>
        </div>
    );
};

export default Sobre;
