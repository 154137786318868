import Airtable from 'airtable';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

console.log('API Key:', process.env.REACT_APP_AIRTABLE_API_KEY);
console.log('Base ID:', process.env.REACT_APP_AIRTABLE_BASE_ID);

Airtable.configure({
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
});

const base = Airtable.base(process.env.REACT_APP_AIRTABLE_BASE_ID);
const table = base('Anuncios'); // Especificando a tabela 'Anuncios'
const usuariosTable = base('Usuarios');

const uploadImagem = async (file) => {
  console.log('Iniciando upload da imagem:', file);
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  try {
    console.log('Comprimindo imagem...');
    const compressedFile = await imageCompression(file, options);
    console.log('Imagem comprimida:', compressedFile);

    const formData = new FormData();
    formData.append('image', compressedFile, compressedFile.name);

    console.log('FormData criado:', formData);
    console.log('Chave da API:', process.env.REACT_APP_IMGBB_API_KEY);

    console.log('Iniciando requisição para ImgBB...');
    const response = await axios.post('https://api.imgbb.com/1/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        key: process.env.REACT_APP_IMGBB_API_KEY,
      },
    });

    console.log('Resposta recebida:', response);

    if (response.data && response.data.data && response.data.data.url) {
      console.log('URL da imagem:', response.data.data.url);
      return response.data.data.url;
    } else {
      console.error('Resposta inesperada do ImgBB:', response.data);
      throw new Error('URL da imagem não encontrada na resposta');
    }
  } catch (error) {
    console.error('Erro detalhado:', error.response ? error.response.data : error.message);
    console.error('Status do erro:', error.response ? error.response.status : 'Desconhecido');
    console.error('Headers da resposta:', error.response ? error.response.headers : 'Desconhecido');
    throw error;
  }
};

export const criarUsuario = async (userData) => {
  try {
    console.log('Dados do usuário recebidos:', userData);

    let profilePictureUrl = userData.profilePicture;

    // Se a profilePicture for uma URL, use-a diretamente
    if (typeof userData.profilePicture === 'string' && userData.profilePicture.startsWith('http')) {
      console.log('URL da imagem de perfil válida:', profilePictureUrl);
    } else {
      console.log('profilePicture não é uma URL válida, definindo como undefined');
      profilePictureUrl = undefined;
    }

    console.log('Criando registro de usuário com os seguintes dados:', {
      username: userData.username,
      email: userData.email,
      nome: userData.nome,
      profilePicture: profilePictureUrl
    });

    const records = await usuariosTable.create([
      {
        fields: {
          username: userData.username,
          email: userData.email,
          nome: userData.nome,
          profilePicture: profilePictureUrl,
          // Adicione outros campos conforme necessário
        },
      },
    ]);

    console.log('Resposta do Airtable:', records);

    if (records && records.length > 0) {
      const createdUser = {
        id: records[0].id,
        ...records[0].fields,
      };
      console.log('Usuário criado com sucesso:', createdUser);
      return createdUser;
    }
    console.log('Nenhum registro retornado pelo Airtable');
    return null;
  } catch (error) {
    console.error('Erro detalhado ao criar usuário:', error.message, error.stack);
    throw error;
  }
};

export const buscarUsuarioPorUsername = async (identifier) => {
  try {
    console.log('Buscando usuário no Airtable:', identifier);
    let formula;
    if (identifier.startsWith('rec')) {
      formula = `RECORD_ID() = '${identifier}'`;
    } else {
      formula = `{username} = '${identifier}'`;
    }
    const records = await base('Usuarios').select({
      filterByFormula: formula,
      maxRecords: 1
    }).firstPage();
    console.log('Registros encontrados:', records);
    if (records.length > 0) {
      const user = {
        id: records[0].id,
        ...records[0].fields,
      };
      console.log('Usuário encontrado:', user);
      return user;
    }
    console.log('Usuário não encontrado');
    return null;
  } catch (error) {
    console.error('Erro ao buscar usuário:', error);
    throw error;
  }
};

export const atualizarUsuario = async (identifier, dadosAtualizados, profileImageFile) => {
  try {
    console.log('Atualizando usuário:', identifier, dadosAtualizados);

    if (!identifier) {
      throw new Error('Identificador do usuário não fornecido');
    }

    // Buscar o usuário
    const usuario = await buscarUsuarioPorUsername(identifier);
    if (!usuario) {
      throw new Error('Usuário não encontrado');
    }

    const fieldsToUpdate = { ...dadosAtualizados };

    // Remova campos que não devem ser atualizados
    const camposParaRemover = ['id', 'dataCriacao', 'RECORD_ID()'];
    camposParaRemover.forEach(campo => delete fieldsToUpdate[campo]);

    // Remova campos vazios ou undefined
    Object.keys(fieldsToUpdate).forEach(key => {
      if (fieldsToUpdate[key] === '' || fieldsToUpdate[key] === undefined) {
        delete fieldsToUpdate[key];
      }
    });

    console.log('Campos a serem atualizados:', fieldsToUpdate);

    // Se tiver uma nova imagem de perfil, faça o upload para o ImgBB
    if (profileImageFile) {
      try {
        const imageUrl = await uploadImagem(profileImageFile);
        fieldsToUpdate.profilePicture = imageUrl;
      } catch (error) {
        console.error('Erro ao fazer upload da imagem:', error);
        throw new Error('Falha ao fazer upload da imagem de perfil');
      }
    }

    // Atualizar os campos
    const updateData = [{
      id: usuario.id,
      fields: fieldsToUpdate
    }];

    console.log('Dados enviados para o Airtable:', JSON.stringify(updateData, null, 2));

    const updatedRecords = await base('Usuarios').update(updateData);

    console.log('Resposta do Airtable:', updatedRecords);

    if (updatedRecords && updatedRecords.length > 0) {
      return {
        id: updatedRecords[0].id,
        ...updatedRecords[0].fields,
      };
    }
    return null;
  } catch (error) {
    console.error('Erro detalhado ao atualizar usuário:', error.message, error.stack);
    throw error;
  }
};

export const fetchAnuncios = async (page = 1, pageSize = 20, apenasDestaque = false) => {
  try {
    const offset = (page - 1) * pageSize;
    let filterByFormula = '';
    if (apenasDestaque) {
      filterByFormula = '{destaque} = 1';
    }
    const records = await table.select({
      maxRecords: pageSize,
      pageSize: pageSize,
      offset: offset,
      filterByFormula: filterByFormula,
      fields: ['titulo', 'descricao', 'preco', 'imagem', 'categoria', 'destaque', 'usuario', 'frete_gratis', 'marca', 'condicao', 'localizacao', 'dataCriado']
    }).firstPage();
    return records.map((record) => ({
      id: record.id,
      titulo: record.fields.titulo,
      descricao: record.fields.descricao,
      preco: parseFloat(record.fields.preco),
      imagem: record.fields.imagem,
      categoria: record.fields.categoria,
      destaque: record.fields.destaque,
      usuario: record.fields.usuario,
      frete_gratis: record.fields.frete_gratis || false,
      marca: record.fields.marca,
      condicao: record.fields.condicao,
      localizacao: record.fields.localizacao,
      dataCriado: record.fields.dataCriado,
    }));
  } catch (error) {
    console.error('Erro ao buscar anúncios:', error);
    return [];
  }
};

export const criarAnuncio = async (anuncio) => {
  try {
    const record = await table.create([
      {
        fields: {
          titulo: anuncio.titulo,
          descricao: anuncio.descricao,
          marca: anuncio.marca,
          preco: parseFloat(anuncio.preco),
          categoria: anuncio.categoria,
          destaque: anuncio.destaque,
          frete_gratis: anuncio.frete_gratis,
          localizacao: anuncio.localizacao,
          condicao: anuncio.condicao,
          modelo: anuncio.modelo,
          ano: parseInt(anuncio.ano),
          material: anuncio.material,
          diametro: parseFloat(anuncio.diametro),
          movimento: anuncio.movimento,
          caracteristicas: anuncio.caracteristicas,
          imagem: anuncio.imagem,
          usuario: [anuncio.usuario], // Assumindo que  uma relação no Airtable
        }
      }
    ]);
    return record[0];
  } catch (error) {
    console.error('Erro ao criar anúncio no Airtable:', error);
    throw error;
  }
};

export const atualizarAnuncio = async (id, anuncio) => {
  try {
    const record = await table.update(id, {
      titulo: anuncio.titulo,
      descricao: anuncio.descricao,
      marca: anuncio.marca,
      preco: anuncio.preco,
      imagem: anuncio.imagem,
      categoria: anuncio.categoria,
      destaque: anuncio.destaque,
      frete_gratis: anuncio.frete_gratis,
      localizacao: anuncio.localizacao,
      condicao: anuncio.condicao,
      modelo: anuncio.modelo,
      ano: anuncio.ano,
      material: anuncio.material,
      diametro: anuncio.diametro,
      movimento: anuncio.movimento,
      caracteristicas: anuncio.caracteristicas,
    });
    return { id: record.id, ...record.fields };
  } catch (error) {
    console.error('Erro ao atualizar anúncio:', error);
    throw error;
  }
};

export const gerarItensTeste = async () => {
  const itensTeste = [
    {
      titulo: "Rolex Submariner",
      descricao: "Relógio de luxo à prova d'água, perfeito para mergulhadores",
      preco: 40000,
      imagem: "https://placehold.co/600x400?text=Rolex+Submariner",
      categoria: "Luxo",
      destaque: true
    },
    {
      titulo: "Casio G-Shock",
      descricao: "Relógio esportivo resistente a choques e à água",
      preco: 500,
      imagem: "https://placehold.co/600x400?text=Casio+G-Shock",
      categoria: "Esportivo",
      destaque: false
    },
    {
      titulo: "Omega Speedmaster",
      descricao: "O famoso relógio que foi à Lua",
      preco: 25000,
      imagem: "https://placehold.co/600x400?text=Omega+Speedmaster",
      categoria: "Luxo",
      destaque: true
    },
    {
      titulo: "Apple Watch Series 7",
      descricao: "O smartwatch mais avançado da Apple",
      preco: 3000,
      imagem: "https://placehold.co/600x400?text=Apple+Watch+Series+7",
      categoria: "Smartwatch",
      destaque: true
    },
    {
      titulo: "Seiko SKX007",
      descricao: "Relógio de mergulho clássico e acessível",
      preco: 2000,
      imagem: "https://placehold.co/600x400?text=Seiko+SKX007",
      categoria: "Esportivo",
      destaque: false
    },
    {
      titulo: "Cartier Tank",
      descricao: "Ícone do design de relógios, elegante e atemporal",
      preco: 15000,
      imagem: "https://placehold.co/600x400?text=Cartier+Tank",
      categoria: "Luxo",
      destaque: false
    },
    {
      titulo: "Timex Weekender",
      descricao: "Relógio casual e versátil para o dia a dia",
      preco: 300,
      imagem: "https://placehold.co/600x400?text=Timex+Weekender",
      categoria: "Vintage",
      destaque: false
    },
    {
      titulo: "Garmin Fenix 6",
      descricao: "Smartwatch robusto para atletas e aventureiros",
      preco: 4000,
      imagem: "https://placehold.co/600x400?text=Garmin+Fenix+6",
      categoria: "Smartwatch",
      destaque: false
    }
  ];

  try {
    for (const item of itensTeste) {
      await table.create(item);
    }
    console.log('Itens de teste adicionados com sucesso!');
  } catch (error) {
    console.error('Erro ao adicionar itens de teste:', error);
    throw error;
  }
};

export const fetchAnuncioById = async (id) => {
  try {
    const record = await base('Anuncios').find(id);
    const usuarioId = record.fields.usuario[0];
    const usuario = await base('Usuarios').find(usuarioId);

    return {
      id: record.id,
      ...record.fields,
      vendedor: {
        id: usuario.id,
        nome: usuario.fields.nome,
        telefone: usuario.fields.telefone,
        email: usuario.fields.email,
        profilePicture: usuario.fields.profilePicture,
        instagram: usuario.fields.instagramUrl,
        facebook: usuario.fields.facebookUrl,
        twitter: usuario.fields.twitterUrl,
      }
    };
  } catch (error) {
    console.error('Erro ao buscar anúncio por ID:', error);
    throw error;
  }
};

export const buscarAnunciosRelacionados = async (anuncioAtual, limite = 5) => {
  try {
    const formula = `OR(
      {marca} = '${anuncioAtual.marca}',
      {categoria} = '${anuncioAtual.categoria}',
      {condicao} = '${anuncioAtual.condicao}',
      ABS({preco} - ${anuncioAtual.preco}) <= ${anuncioAtual.preco * 0.2}
    )`;

    const records = await base('Anuncios').select({
      filterByFormula: formula,
      sort: [
        { field: 'dataCriado', direction: 'desc' }
      ],
      maxRecords: limite * 2 + 1 // Buscamos o dobro + 1 para ter mais opções para filtrar
    }).firstPage();

    const anuncios = records
      .filter(record => record.id !== anuncioAtual.id) // Filtramos o anúncio atual aqui
      .map(record => ({
        id: record.id,
        ...record.fields
      }));

    // Calculamos a pontuação de relevância para cada anúncio
    const anunciosPontuados = anuncios.map(anuncio => ({
      ...anuncio,
      pontuacao: calcularPontuacaoRelevancia(anuncioAtual, anuncio)
    }));

    // Ordenamos por pontuação e pegamos os top N
    return anunciosPontuados
      .sort((a, b) => b.pontuacao - a.pontuacao)
      .slice(0, limite);
  } catch (error) {
    console.error('Erro ao buscar anúncios relacionados:', error);
    throw error;
  }
};

const calcularPontuacaoRelevancia = (anuncioAtual, anuncioRelacionado) => {
  let pontuacao = 0;

  // Mesma marca
  if (anuncioAtual.marca === anuncioRelacionado.marca) pontuacao += 3;

  // Mesma categoria
  if (anuncioAtual.categoria === anuncioRelacionado.categoria) pontuacao += 2;

  // Mesma condição
  if (anuncioAtual.condicao === anuncioRelacionado.condicao) pontuacao += 1;

  // Preço similar (dentro de 20%)
  const diferencaPreco = Math.abs(anuncioAtual.preco - anuncioRelacionado.preco);
  if (diferencaPreco <= anuncioAtual.preco * 0.2) pontuacao += 2;

  // Anúncios mais recentes recebem uma pontuação adicional
  const diasDesdePublicacao = (new Date() - new Date(anuncioRelacionado.dataCriado)) / (1000 * 60 * 60 * 24);
  pontuacao += Math.max(0, 1 - diasDesdePublicacao / 30); // Máximo de 1 ponto extra para anúncios com menos de 30 dias

  return pontuacao;
};

export const fetchFavoritos = async (userId) => {
  try {
    // Buscar o usuário
    const userRecords = await base('Usuarios').select({
      filterByFormula: `RECORD_ID() = '${userId}'`,
      fields: ['favoritos']
    }).firstPage();

    if (userRecords.length === 0) {
      throw new Error('Usuário não encontrado');
    }

    const favoritosIds = userRecords[0].fields.favoritos || [];

    // Buscar os anúncios favoritados
    const anunciosPromises = favoritosIds.map(id => 
      base('Anuncios').find(id)
    );

    const anuncios = await Promise.all(anunciosPromises);

    return anuncios.map(record => ({
      id: record.id,
      ...record.fields
    }));

  } catch (error) {
    console.error('Erro ao buscar favoritos:', error);
    throw error;
  }
};

export const removerFavorito = async (userId, anuncioId) => {
  try {
    const userRecords = await base('Usuarios').select({
      filterByFormula: `RECORD_ID() = '${userId}'`,
      fields: ['favoritos']
    }).firstPage();

    if (userRecords.length === 0) {
      throw new Error('Usuário não encontrado');
    }

    const user = userRecords[0];
    const favoritos = user.fields.favoritos || [];
    const novosFavoritos = favoritos.filter(id => id !== anuncioId);

    await base('Usuarios').update([
      {
        id: user.id,
        fields: {
          favoritos: novosFavoritos
        }
      }
    ]);

  } catch (error) {
    console.error('Erro ao remover favorito:', error);
    throw error;
  }
};

export const adicionarFavorito = async (userId, anuncioId) => {
  try {
    const userRecords = await base('Usuarios').select({
      filterByFormula: `RECORD_ID() = '${userId}'`,
      fields: ['favoritos']
    }).firstPage();

    if (userRecords.length === 0) {
      throw new Error('Usuário não encontrado');
    }

    const user = userRecords[0];
    const favoritos = user.fields.favoritos || [];
    
    if (!favoritos.includes(anuncioId)) {
      const novosFavoritos = [...favoritos, anuncioId];

      await base('Usuarios').update([
        {
          id: user.id,
          fields: {
            favoritos: novosFavoritos
          }
        }
      ]);
    }

  } catch (error) {
    console.error('Erro ao adicionar favorito:', error);
    throw error;
  }
};

export const fetchBanners = async () => {
  try {
    const records = await base('Banners').select({
      filterByFormula: '{ativo} = 1',
      sort: [{ field: 'ordem', direction: 'asc' }]
    }).all();

    return records.map(record => ({
      id: record.id,
      nome: record.get('nome'),
      imagem: record.get('imagem')[0].url,
      link: record.get('link'),
      ordem: record.get('ordem')
    }));
  } catch (error) {
    console.error('Erro ao buscar banners:', error);
    return [];
  }
};

export const excluirAnuncio = async (id) => {
  try {
    await table.destroy(id);
  } catch (error) {
    console.error('Erro ao excluir anúncio:', error);
    throw error;
  }
};