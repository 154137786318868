import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { fetchAnuncios, fetchBanners } from '../utils/airtable';
import { ClockIcon, FireIcon, UserGroupIcon, ShieldCheckIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useInView } from 'react-intersection-observer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Home = () => {
  const [anunciosRecentes, setAnunciosRecentes] = useState([]);
  const [anunciosDestaque, setAnunciosDestaque] = useState([]);
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  const carregarDados = useCallback(async () => {
    try {
      const [anuncios, destaques, bannersDados] = await Promise.all([
        fetchAnuncios(1, 8),
        fetchAnuncios(1, 4, true),
        fetchBanners()
      ]);
      
      setAnunciosRecentes(anuncios);
      setAnunciosDestaque(destaques);
      setBanners(bannersDados);
      
      console.log('Banners carregados:', bannersDados);
    } catch (error) {
      console.error('Erro ao carregar dados:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    carregarDados();
  }, [carregarDados]);

  const categorias = useMemo(() => [
    { nome: 'Relógios de Luxo', icone: '⌚', slug: 'relogios-de-luxo' },
    { nome: 'Relógios Esportivos', icone: '🏃', slug: 'relogios-esportivos' },
    { nome: 'Relógios Vintage', icone: '🕰️', slug: 'relogios-vintage' },
    { nome: 'Smartwatches', icone: '📱', slug: 'smartwatches' },
  ], []);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const sliderSettings = useMemo(() => ({
    dots: banners.length > 1,
    arrows: banners.length > 1,
    infinite: banners.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: banners.length > 1,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
  }), [banners.length]);

  const BannerImage = useCallback(({ banner }) => (
    <Link to={banner.link}>
      <div className="w-full">
        <img 
          src={banner.imagem} 
          alt={banner.nome} 
          className="w-full h-auto"
        />
      </div>
    </Link>
  ), []);

  const BannerSkeleton = useCallback(() => (
    <div className="mb-12">
      <Skeleton height={300} />
    </div>
  ), []);

  const AnunciosSkeleton = useCallback(() => (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {[...Array(8)].map((_, index) => (
        <div key={index} className="bg-white p-4 rounded-lg shadow-md">
          <Skeleton height={150} />
          <Skeleton height={20} width={120} className="mt-2" />
          <Skeleton height={16} width={80} className="mt-1" />
          <Skeleton height={24} width={100} className="mt-2" />
        </div>
      ))}
    </div>
  ), []);

  const memoizedBanners = useMemo(() => (
    banners.length > 0 && (
      <div className="mb-12">
        {banners.length === 1 ? (
          <BannerImage banner={banners[0]} />
        ) : (
          <Slider {...sliderSettings}>
            {banners.map((banner) => (
              <div key={banner.id}>
                <BannerImage banner={banner} />
              </div>
            ))}
          </Slider>
        )}
      </div>
    )
  ), [banners, sliderSettings, BannerImage]);

  const AnuncioCard = ({ anuncio, isDestaque = false }) => (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={`bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition duration-300 flex flex-col ${isDestaque ? 'border-2 border-yellow-400' : ''}`}
    >
      <Link to={`/anuncio/${anuncio.id}`} className="block">
        <div className="relative bg-white flex items-center justify-center overflow-hidden h-48">
          <img 
            src={anuncio.imagem} 
            alt={anuncio.titulo} 
            className="max-w-full max-h-full object-contain"
          />
          {isDestaque && (
            <span className="absolute top-1 left-1 bg-yellow-400 text-xs font-semibold px-1 py-0.5 rounded-full">
              Destaque
            </span>
          )}
        </div>
        <div className="p-2">
          <h3 className={`font-semibold text-sm mb-0.5 ${isDestaque ? 'text-yellow-600' : 'text-gray-800'}`}>
            {anuncio.titulo}
          </h3>
          <p className="text-xs text-gray-600 mb-0.5">{anuncio.marca}</p>
          <p className="text-sm font-bold text-gray-900 mb-1">R$ {anuncio.preco.toFixed(2)}</p>
          <p className="text-xs text-gray-600 mb-1 line-clamp-2">{anuncio.descricao}</p>
          <div className="flex justify-between items-end">
            <p className="text-xs text-gray-500">
              {anuncio.localizacao}
            </p>
            <div className="text-xs text-gray-500 flex items-center">
              <ClockIcon className="w-3 h-3 mr-1" />
              {new Date(anuncio.dataCriado).toLocaleDateString()}
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  );

  const AnuncioSkeleton = () => (
    <div className="bg-white p-4 rounded-lg shadow-md h-full">
      <Skeleton height={200} />
      <Skeleton height={24} width={150} className="mt-4" />
      <Skeleton count={2} className="mt-2" />
      <Skeleton height={24} width={100} className="mt-2" />
    </div>
  );

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <BannerSkeleton />
        <h2 className="text-2xl font-bold mb-4">
          <Skeleton width={200} />
        </h2>
        <AnunciosSkeleton />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {memoizedBanners}

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4">Categorias em Destaque</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {categorias.map((categoria) => (
            <Link
              key={categoria.nome}
              to={`/categoria/${categoria.slug}`}
              className="bg-white p-4 rounded-lg shadow-md text-center hover:shadow-lg transition duration-300"
            >
              <span className="text-4xl mb-2 block">{categoria.icone}</span>
              <span className="font-semibold">{categoria.nome}</span>
            </Link>
          ))}
        </div>
      </section>

      <AnimatePresence>
        <section className="mb-12" ref={ref}>
          <h2 className="text-2xl font-bold mb-4">Anúncios em Destaque</h2>
          {loading ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <Skeleton height={200} />
                  <Skeleton height={24} width={150} className="mt-4" />
                  <Skeleton count={2} className="mt-2" />
                </div>
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {anunciosDestaque.map((anuncio, index) => (
                <motion.div
                  key={anuncio.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={inView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Link
                    to={`/anuncio/${anuncio.id}`}
                    className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex flex-col md:flex-row"
                  >
                    <img src={anuncio.imagem} alt={anuncio.titulo} className="w-full md:w-1/2 h-64 object-cover mb-4 md:mb-0 md:mr-6 rounded" />
                    <div className="flex flex-col justify-between">
                      <div>
                        <h3 className="font-semibold text-xl mb-1">{anuncio.titulo}</h3>
                        {anuncio.marca && <p className="text-sm text-gray-600 mb-2">{anuncio.marca}</p>}
                        <p className="text-gray-600 mb-4">{anuncio.descricao.substring(0, 100)}...</p>
                      </div>
                      <div>
                        <p className="text-2xl font-bold text-green-600 mb-2">R$ {anuncio.preco.toFixed(2)}</p>
                        <div className="flex items-center text-sm text-gray-500">
                          <FireIcon className="w-5 h-5 mr-2 text-orange-500" />
                          <span>Anúncio em Destaque</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </motion.div>
              ))}
            </div>
          )}
        </section>
      </AnimatePresence>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4">Anúncios Recentes</h2>
        {loading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {[...Array(8)].map((_, index) => (
              <AnuncioSkeleton key={index} />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {anunciosRecentes.map((anuncio) => (
              <AnuncioCard key={anuncio.id} anuncio={anuncio} isDestaque={anuncio.destaque} />
            ))}
          </div>
        )}
      </section>

      <div className="bg-gray-100 py-12 mt-16 rounded-lg">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center text-center">
              <MagnifyingGlassIcon className="h-16 w-16 text-gray-700 mb-4" />
              <h3 className="text-lg font-semibold mb-2 text-gray-800">Encontre o relógio perfeito</h3>
              <p className="text-sm text-gray-600">
                Navegue por uma ampla seleção de relógios de todas as marcas e estilos.
              </p>
              <Link to="/anuncios" className="text-gray-700 hover:text-gray-900 hover:underline mt-2 text-sm">
                Explorar anúncios
              </Link>
            </div>

            <div className="flex flex-col items-center text-center">
              <UserGroupIcon className="h-16 w-16 text-gray-700 mb-4" />
              <h3 className="text-lg font-semibold mb-2 text-gray-800">Conecte-se diretamente</h3>
              <p className="text-sm text-gray-600">
                Entre em contato com vendedores e faça negócios de forma direta e transparente.
              </p>
              <Link to="/como-funciona" className="text-gray-700 hover:text-gray-900 hover:underline mt-2 text-sm">
                Como funciona
              </Link>
            </div>

            <div className="flex flex-col items-center text-center">
              <ShieldCheckIcon className="h-16 w-16 text-gray-700 mb-4" />
              <h3 className="text-lg font-semibold mb-2 text-gray-800">Compra e venda seguras</h3>
              <p className="text-sm text-gray-600">
                Oferecemos dicas e ferramentas para garantir transações seguras entre usuários.
              </p>
              <Link to="/seguranca" className="text-gray-700 hover:text-gray-900 hover:underline mt-2 text-sm">
                Nossas medidas de segurança
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
