import React from 'react';
import { Link } from 'react-router-dom';
import { ClockIcon, ChatBubbleLeftRightIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">Sobre o TimeSpot</h3>
            <p className="text-sm">TimeSpot é uma plataforma de marketplace online dedicada à compra, venda e troca de relógios de pulso.</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Links Rápidos</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="text-sm hover:underline">Home</Link></li>
              <li><Link to="/anuncios" className="text-sm hover:underline">Anúncios</Link></li>
              <li><Link to="/sobre" className="text-sm hover:underline">Sobre</Link></li>
              <li><Link to="/contato" className="text-sm hover:underline">Contato</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Categorias</h3>
            <ul className="space-y-2">
              <li><Link to="/categoria/relogios-de-luxo" className="text-sm hover:underline">Relógios de Luxo</Link></li>
              <li><Link to="/categoria/relogios-esportivos" className="text-sm hover:underline">Relógios Esportivos</Link></li>
              <li><Link to="/categoria/relogios-vintage" className="text-sm hover:underline">Relógios Vintage</Link></li>
              <li><Link to="/categoria/smartwatches" className="text-sm hover:underline">Smartwatches</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Contato</h3>
            <ul className="space-y-2">
              <li className="flex items-center">
                <ClockIcon className="w-5 h-5 mr-2" />
                <span className="text-sm">Seg - Sex: 9h às 18h</span>
              </li>
              <li className="flex items-center">
                <ChatBubbleLeftRightIcon className="w-5 h-5 mr-2" />
                <span className="text-sm">suporte@timespot.com.br</span>
              </li>
              <li className="flex items-center">
                <ShieldCheckIcon className="w-5 h-5 mr-2" />
                <span className="text-sm">Compra e venda seguras</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p className="text-sm">&copy; 2024 TimeSpot. Todos os direitos reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
