import React, { useContext, useState, useCallback, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { CameraIcon, TagIcon, CurrencyDollarIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { criarAnuncio } from '../utils/airtable';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

const CriarAnuncio = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    titulo: '',
    descricao: '',
    marca: '',
    preco: '',
    categoria: '',
    destaque: false,
    frete_gratis: false,
    localizacao: '',
    condicao: '',
    modelo: '',
    ano: '',
    material: '',
    diametro: '',
    movimento: '',
    caracteristicas: '',
  });
  const [imagem, setImagem] = useState(null);
  const [previewImagem, setPreviewImagem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleImagemChange = (e) => {
    if (e.target.files[0]) {
      setImagem(e.target.files[0]);
      setPreviewImagem(URL.createObjectURL(e.target.files[0]));
    }
  };

  const uploadImagem = useCallback(async (file) => {
    console.log('Iniciando upload da imagem:', file);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    try {
      console.log('Comprimindo imagem...');
      const compressedFile = await imageCompression(file, options);
      console.log('Imagem comprimida:', compressedFile);

      const formData = new FormData();
      formData.append('image', compressedFile, compressedFile.name);

      console.log('FormData criado:', formData);
      console.log('Chave da API:', process.env.REACT_APP_IMGBB_API_KEY);

      console.log('Iniciando requisição para ImgBB...');
      const response = await axios.post('https://api.imgbb.com/1/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          key: process.env.REACT_APP_IMGBB_API_KEY,
        },
      });

      console.log('Resposta recebida:', response);

      if (response.data && response.data.data && response.data.data.url) {
        console.log('URL da imagem:', response.data.data.url);
        return response.data.data.url;
      } else {
        console.error('Resposta inesperada do ImgBB:', response.data);
        throw new Error('URL da imagem não encontrada na resposta');
      }
    } catch (error) {
      console.error('Erro detalhado:', error.response ? error.response.data : error.message);
      console.error('Status do erro:', error.response ? error.response.status : 'Desconhecido');
      console.error('Headers da resposta:', error.response ? error.response.headers : 'Desconhecido');
      throw error;
    }
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.titulo) newErrors.titulo = "Título é obrigatório";
    if (!formData.descricao) newErrors.descricao = "Descrição é obrigatória";
    if (!formData.marca) newErrors.marca = "Marca é obrigatória";
    if (!formData.preco) newErrors.preco = "Preço é obrigatório";
    if (!formData.categoria) newErrors.categoria = "Categoria é obrigatória";
    if (!formData.localizacao) newErrors.localizacao = "Localização é obrigatória";
    if (!formData.condicao) newErrors.condicao = "Condição é obrigatória";
    if (!imagem) newErrors.imagem = "Imagem é obrigatória";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      console.log('Iniciando submissão do formulário');
      console.log('Dados do formulário:', formData);
      console.log('Imagem selecionada:', imagem);

      let imagemUrl = '';
      if (imagem) {
        console.log('Iniciando upload da imagem...');
        imagemUrl = await uploadImagem(imagem);
        console.log('Upload da imagem concluído. URL:', imagemUrl);
      } else {
        console.log('Nenhuma imagem selecionada');
      }

      // Criar o objeto anúncio com todos os dados
      const novoAnuncio = {
        ...formData,
        imagem: imagemUrl,
        usuario: user.id, // Assumindo que o user.id está disponível no contexto
      };

      console.log('Criando anúncio no Airtable:', novoAnuncio);
      const anuncioCriado = await criarAnuncio(novoAnuncio);
      console.log('Anúncio criado com sucesso:', anuncioCriado);

      toast.success('Anúncio criado com sucesso!');
      navigate('/meus-anuncios'); // Redireciona para a página de anúncios do usuário

    } catch (error) {
      console.error('Erro ao criar anúncio:', error);
      toast.error('Erro ao criar anúncio. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md"
    >
      <h2 className="text-2xl font-bold mb-6">Criar Novo Anúncio</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="titulo" className="block text-sm font-medium text-gray-700">
            Título do anúncio
          </label>
          <input
            type="text"
            name="titulo"
            id="titulo"
            value={formData.titulo}
            onChange={handleChange}
            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              errors.titulo ? 'border-red-300' : ''
            }`}
          />
          {errors.titulo && <p className="mt-2 text-sm text-red-600">{errors.titulo}</p>}
        </div>

        <div>
          <label htmlFor="descricao" className="block text-sm font-medium text-gray-700">
            Descrição
          </label>
          <textarea
            name="descricao"
            id="descricao"
            rows="4"
            value={formData.descricao}
            onChange={handleChange}
            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              errors.descricao ? 'border-red-300' : ''
            }`}
          ></textarea>
          {errors.descricao && <p className="mt-2 text-sm text-red-600">{errors.descricao}</p>}
        </div>

        <div>
          <label htmlFor="marca" className="block text-sm font-medium text-gray-700">
            Marca
          </label>
          <input
            type="text"
            name="marca"
            id="marca"
            value={formData.marca}
            onChange={handleChange}
            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              errors.marca ? 'border-red-300' : ''
            }`}
          />
          {errors.marca && <p className="mt-2 text-sm text-red-600">{errors.marca}</p>}
        </div>

        <div>
          <label htmlFor="preco" className="block text-sm font-medium text-gray-700">
            Preço
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">R$</span>
            </div>
            <input
              type="number"
              name="preco"
              id="preco"
              value={formData.preco}
              onChange={handleChange}
              className={`block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md ${
                errors.preco ? 'border-red-300' : ''
              }`}
              placeholder="0.00"
            />
          </div>
          {errors.preco && <p className="mt-2 text-sm text-red-600">{errors.preco}</p>}
        </div>

        <div>
          <label htmlFor="categoria" className="block text-sm font-medium text-gray-700">
            Categoria
          </label>
          <select
            name="categoria"
            id="categoria"
            value={formData.categoria}
            onChange={handleChange}
            className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
              errors.categoria ? 'border-red-300' : ''
            }`}
          >
            <option value="">Selecione uma categoria</option>
            <option value="Luxo">Luxo</option>
            <option value="Esportivo">Esportivo</option>
            <option value="Vintage">Vintage</option>
            <option value="Smartwatch">Smartwatch</option>
            <option value="Aviador">Aviador</option>
            <option value="Bolso">Bolso</option>
            <option value="Grife">Grife</option>
            <option value="Mergulho">Mergulho</option>
            <option value="Ecológico">Ecológico</option>
            <option value="Militar">Militar</option>
            <option value="Minimalista">Minimalista</option>
            <option value="Skeleton">Skeleton</option>
          </select>
          {errors.categoria && <p className="mt-2 text-sm text-red-600">{errors.categoria}</p>}
        </div>

        <div>
          <label htmlFor="localizacao" className="block text-sm font-medium text-gray-700">
            Localização
          </label>
          <input
            type="text"
            name="localizacao"
            id="localizacao"
            value={formData.localizacao}
            onChange={handleChange}
            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              errors.localizacao ? 'border-red-300' : ''
            }`}
          />
          {errors.localizacao && <p className="mt-2 text-sm text-red-600">{errors.localizacao}</p>}
        </div>

        <div>
          <label htmlFor="condicao" className="block text-sm font-medium text-gray-700">
            Condição
          </label>
          <select
            name="condicao"
            id="condicao"
            value={formData.condicao}
            onChange={handleChange}
            className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
              errors.condicao ? 'border-red-300' : ''
            }`}
          >
            <option value="">Selecione a condição</option>
            <option value="Novo">Novo</option>
            <option value="Usado">Usado</option>
          </select>
          {errors.condicao && <p className="mt-2 text-sm text-red-600">{errors.condicao}</p>}
        </div>

        <div>
          <label htmlFor="modelo" className="block text-sm font-medium text-gray-700">
            Modelo
          </label>
          <input
            type="text"
            name="modelo"
            id="modelo"
            value={formData.modelo}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div>
          <label htmlFor="ano" className="block text-sm font-medium text-gray-700">
            Ano
          </label>
          <input
            type="number"
            name="ano"
            id="ano"
            value={formData.ano}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div>
          <label htmlFor="material" className="block text-sm font-medium text-gray-700">
            Material
          </label>
          <input
            type="text"
            name="material"
            id="material"
            value={formData.material}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div>
          <label htmlFor="diametro" className="block text-sm font-medium text-gray-700">
            Diâmetro (mm)
          </label>
          <input
            type="number"
            name="diametro"
            id="diametro"
            value={formData.diametro}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div>
          <label htmlFor="movimento" className="block text-sm font-medium text-gray-700">
            Movimento
          </label>
          <input
            type="text"
            name="movimento"
            id="movimento"
            value={formData.movimento}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div>
          <label htmlFor="caracteristicas" className="block text-sm font-medium text-gray-700">
            Características
          </label>
          <textarea
            name="caracteristicas"
            id="caracteristicas"
            rows="3"
            value={formData.caracteristicas}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          ></textarea>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="destaque"
            name="destaque"
            checked={formData.destaque}
            onChange={handleChange}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label htmlFor="destaque" className="ml-2 block text-sm text-gray-900">
            Anúncio em destaque
          </label>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="frete_gratis"
            name="frete_gratis"
            checked={formData.frete_gratis}
            onChange={handleChange}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label htmlFor="frete_gratis" className="ml-2 block text-sm text-gray-900">
            Frete grátis
          </label>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Imagem do relógio</label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              {previewImagem ? (
                <img src={previewImagem} alt="Preview" className="mx-auto h-32 w-32 object-cover rounded-md" />
              ) : (
                <CameraIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
              )}
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="imagem-upload"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <span>Carregar uma imagem</span>
                  <input
                    id="imagem-upload"
                    name="imagem-upload"
                    type="file"
                    className="sr-only"
                    onChange={handleImagemChange}
                    accept="image/*"
                  />
                </label>
                <p className="pl-1">ou arraste e solte</p>
              </div>
              <p className="text-xs text-gray-500">PNG, JPG, GIF até 10MB</p>
            </div>
          </div>
          {errors.imagem && <p className="mt-2 text-sm text-red-600">{errors.imagem}</p>}
        </div>

        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={loading}
          >
            {loading ? 'Criando Anúncio...' : 'Criar Anúncio'}
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default CriarAnuncio;
