import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { atualizarUsuario } from '../utils/airtable';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CameraIcon } from '@heroicons/react/24/solid';
import TermosDeUsoModal from './TermosDeUsoModal';

const CompletarCadastro = () => {
  const { user, updateUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    bio: '',
    localizacao: '',
    dataNascimento: '',
    instagramUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    notificacaoEmail: false,
    notificacaoSMS: false,
    notificacaoPush: false,
    username: ''
  });
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [isTermosModalOpen, setIsTermosModalOpen] = useState(false);
  const [aceitouTermos, setAceitouTermos] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (user) {
      setFormData(prevData => ({
        ...prevData,
        nome: user.nome || user.name || '',
        email: user.email || '',
        username: user.username || user.email || '',
        telefone: user.telefone || user.phoneNumber || '',
        localizacao: user.localizacao || '',
        dataNascimento: user.dataNascimento || '',
        bio: user.bio || '',
        instagramUrl: user.instagramUrl || '',
        facebookUrl: user.facebookUrl || '',
        twitterUrl: user.twitterUrl || '',
        notificacaoEmail: user.notificacaoEmail || false,
        notificacaoSMS: user.notificacaoSMS || false,
        notificacaoPush: user.notificacaoPush || false,
      }));
      if (user.profilePicture) {
        setProfileImage(user.profilePicture);
      }
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    // Limpar o erro do campo quando ele for preenchido
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setProfileImageFile(e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.nome.trim()) newErrors.nome = "Nome é obrigatório";
    if (!formData.telefone.trim()) newErrors.telefone = "Telefone é obrigatório";
    if (!formData.localizacao.trim()) newErrors.localizacao = "Localização é obrigatória";
    if (!formData.dataNascimento.trim()) newErrors.dataNascimento = "Data de nascimento é obrigatória";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }
    if (!aceitouTermos) {
      setIsTermosModalOpen(true);
      return;
    }
    if (!user) {
      toast.error('Usuário não encontrado. Por favor, faça login novamente.');
      return;
    }
    setLoading(true);
    try {
      console.log('Submetendo formulário com identificador:', user.id || user.username);
      const dadosAtualizados = {
        ...formData
      };
      
      // Remover campos que não devem ser atualizados
      const camposParaRemover = ['id', 'dataCriacao', 'RECORD_ID()', 'email', 'username', 'cadastroCompleto'];
      camposParaRemover.forEach(campo => delete dadosAtualizados[campo]);

      console.log('Dados a serem atualizados:', dadosAtualizados);

      const usuarioAtualizado = await atualizarUsuario(user.id || user.username, dadosAtualizados, profileImageFile);
      updateUser(usuarioAtualizado);
      toast.success('Cadastro completado com sucesso!');
      navigate('/'); // Redireciona para a página inicial
    } catch (error) {
      console.error('Erro ao completar cadastro:', error);
      toast.error('Erro ao completar cadastro');
    } finally {
      setLoading(false);
    }
  };

  const handleAceitarTermos = () => {
    setAceitouTermos(true);
    setIsTermosModalOpen(false);
    handleSubmit(new Event('submit'));
  };

  if (!user) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-6">Completar Cadastro</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex items-center space-x-6">
          <div className="relative">
            <img
              src={profileImage || 'https://via.placeholder.com/150'}
              alt="Foto de perfil"
              className="w-32 h-32 rounded-full object-cover"
            />
            <label htmlFor="profile-image" className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full cursor-pointer">
              <CameraIcon className="w-5 h-5" />
            </label>
            <input
              type="file"
              id="profile-image"
              accept="image/*"
              className="hidden"
              onChange={handleImageChange}
            />
          </div>
        </div>

        <div>
          <label htmlFor="nome" className="block text-sm font-medium text-gray-700">Nome completo *</label>
          <input
            type="text"
            id="nome"
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            className={`mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.nome ? 'border-red-500' : 'border-gray-300'}`}
            required
          />
          {errors.nome && <p className="mt-1 text-sm text-red-500">{errors.nome}</p>}
        </div>

        <div>
          <label htmlFor="telefone" className="block text-sm font-medium text-gray-700">Telefone *</label>
          <input
            type="tel"
            id="telefone"
            name="telefone"
            value={formData.telefone}
            onChange={handleChange}
            className={`mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.telefone ? 'border-red-500' : 'border-gray-300'}`}
            required
          />
          {errors.telefone && <p className="mt-1 text-sm text-red-500">{errors.telefone}</p>}
        </div>

        <div>
          <label htmlFor="localizacao" className="block text-sm font-medium text-gray-700">Localização *</label>
          <input
            type="text"
            id="localizacao"
            name="localizacao"
            value={formData.localizacao}
            onChange={handleChange}
            className={`mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.localizacao ? 'border-red-500' : 'border-gray-300'}`}
            required
          />
          {errors.localizacao && <p className="mt-1 text-sm text-red-500">{errors.localizacao}</p>}
        </div>

        <div>
          <label htmlFor="dataNascimento" className="block text-sm font-medium text-gray-700">Data de Nascimento *</label>
          <input
            type="date"
            id="dataNascimento"
            name="dataNascimento"
            value={formData.dataNascimento}
            onChange={handleChange}
            className={`mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.dataNascimento ? 'border-red-500' : 'border-gray-300'}`}
            required
          />
          {errors.dataNascimento && <p className="mt-1 text-sm text-red-500">{errors.dataNascimento}</p>}
        </div>

        <div>
          <label htmlFor="bio" className="block text-sm font-medium text-gray-700">Biografia</label>
          <textarea
            id="bio"
            name="bio"
            rows="3"
            value={formData.bio}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          ></textarea>
        </div>

        {/* Adicione os outros campos do formulário aqui */}

        <div className="flex items-center">
          <input
            type="checkbox"
            id="aceitarTermos"
            checked={aceitouTermos}
            onChange={() => setAceitouTermos(!aceitouTermos)}
            className="mr-2"
          />
          <label htmlFor="aceitarTermos">
            Eu li e aceito os <button type="button" onClick={() => setIsTermosModalOpen(true)} className="text-blue-500 underline">Termos de Uso e Serviço</button>
          </label>
        </div>

        <div>
          <button
            type="submit"
            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${aceitouTermos ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            disabled={loading || !aceitouTermos}
          >
            {loading ? 'Salvando...' : 'Completar Cadastro'}
          </button>
        </div>
      </form>

      <TermosDeUsoModal
        isOpen={isTermosModalOpen}
        onClose={() => setIsTermosModalOpen(false)}
        onAccept={handleAceitarTermos}
      />
    </div>
  );
};

export default CompletarCadastro;
