import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { fetchAnuncios } from '../utils/airtable';
import { FireIcon, AdjustmentsHorizontalIcon, Squares2X2Icon as ViewGridIcon, ListBulletIcon as ViewListIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AnimatePresence, motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

console.log('Anuncios component rendered');

const Anuncios = () => {
  const [anuncios, setAnuncios] = useState([]);
  const [anunciosDestaque, setAnunciosDestaque] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

  // Extrair o termo de busca da URL
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [appliedSearchTerm, setAppliedSearchTerm] = useState('');

  // Filtros
  const [categoriaFiltro, setCategoriaFiltro] = useState('');
  const [precoRange, setPrecoRange] = useState([0, 1000000]); // Valor inicial para o range de preço
  const [precoMinMax, setPrecoMinMax] = useState([0, 1000000]); // Valor mínimo e máximo geral para o preço
  const [ordenacao, setOrdenacao] = useState('recentes');
  const [marcasFiltro, setMarcasFiltro] = useState([]);
  const [todasMarcas, setTodasMarcas] = useState([]);
  const [condicaoFiltro, setCondicaoFiltro] = useState('');
  const [localizacaoFiltro, setLocalizacaoFiltro] = useState('');

  const [filtrosExpandidos, setFiltrosExpandidos] = useState(false);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' ou 'list'

  // Novos estados para opções dinâmicas
  const [categoriasDisponiveis, setCategoriasDisponiveis] = useState([]);
  const [condicoesDisponiveis, setCondicoesDisponiveis] = useState([]);
  const [localizacoesDisponiveis, setLocalizacoesDisponiveis] = useState([]);

  useEffect(() => {
    console.log('URL search params changed', location.search);
    const searchParams = new URLSearchParams(location.search);
    const newSearchTerm = searchParams.get('search') || '';
    setCurrentSearchTerm(newSearchTerm);
    setAppliedSearchTerm(newSearchTerm);
  }, [location.search]);

  const carregarAnuncios = useCallback(async (pageNum, isInitialLoad = false) => {
    console.log(`Carregando anúncios: página ${pageNum}, carregamento inicial: ${isInitialLoad}`);
    try {
      setLoading(isInitialLoad);
      setLoadingMore(!isInitialLoad);
      const todosAnuncios = await fetchAnuncios(pageNum);
      const destaques = todosAnuncios.filter(anuncio => anuncio.destaque);
      const regulares = todosAnuncios.filter(anuncio => !anuncio.destaque);
      
      if (isInitialLoad) {
        setAnunciosDestaque(destaques);
        setAnuncios(regulares);

        // Extrair opções únicas para filtros
        const marcasUnicas = [...new Set([...destaques, ...regulares].map(a => a.marca))].filter(Boolean);
        const categoriasUnicas = [...new Set([...destaques, ...regulares].map(a => a.categoria))].filter(Boolean);
        const condicoesUnicas = [...new Set([...destaques, ...regulares].map(a => a.condicao))].filter(Boolean);
        const localizacoesUnicas = [...new Set([...destaques, ...regulares].map(a => a.localizacao))].filter(Boolean);

        setTodasMarcas(marcasUnicas);
        setCategoriasDisponiveis(categoriasUnicas);
        setCondicoesDisponiveis(condicoesUnicas);
        setLocalizacoesDisponiveis(localizacoesUnicas);

        // Encontrar o preço mínimo e máximo entre todos os anúncios
        const precos = [...destaques, ...regulares].map(a => a.preco);
        const minPreco = Math.min(...precos);
        const maxPreco = Math.max(...precos);
        setPrecoMinMax([minPreco, maxPreco]);
        setPrecoRange([minPreco, maxPreco]); // Inicializa o range com os valores min e max
      } else {
        setAnuncios(prevAnuncios => [...prevAnuncios, ...regulares]);
      }
      
      setHasMore(regulares.length > 0);
      setPage(pageNum);
    } catch (error) {
      console.error('Erro ao carregar anúncios:', error);
      setError('Ocorreu um erro ao carregar os anúncios. Por favor, tente novamente mais tarde.');
    } finally {
      setLoading(false);
      setLoadingMore(false);
      console.log('Finalizado o carregamento de anúncios');
    }
  }, []);

  useEffect(() => {
    console.log('Initial load effect triggered');
    carregarAnuncios(1, true);
  }, [carregarAnuncios]);

  const carregarMaisAnuncios = async () => {
    if (!hasMore || loadingMore) return;
    const proximaPagina = page + 1;
    await carregarAnuncios(proximaPagina);
  };

  const filtrarAnuncios = useCallback((anunciosParaFiltrar) => {
    console.log('Filtering ads', { appliedSearchTerm, categoriaFiltro, precoRange, marcasFiltro, condicaoFiltro, localizacaoFiltro });
    return anunciosParaFiltrar.filter(anuncio => {
      const matchesSearch = appliedSearchTerm
        ? anuncio.titulo.toLowerCase().includes(appliedSearchTerm.toLowerCase()) ||
          anuncio.descricao.toLowerCase().includes(appliedSearchTerm.toLowerCase()) ||
          anuncio.categoria.toLowerCase().includes(appliedSearchTerm.toLowerCase())
        : true;
      
      const matchesCategoria = categoriaFiltro ? anuncio.categoria === categoriaFiltro : true;
      const matchesPreco = anuncio.preco >= precoRange[0] && anuncio.preco <= precoRange[1];
      const matchesMarca = marcasFiltro.length > 0 ? marcasFiltro.includes(anuncio.marca) : true;
      const matchesCondicao = condicaoFiltro ? anuncio.condicao === condicaoFiltro : true;
      const matchesLocalizacao = localizacaoFiltro ? anuncio.localizacao === localizacaoFiltro : true;

      return matchesSearch && matchesCategoria && matchesPreco && matchesMarca && matchesCondicao && matchesLocalizacao;
    });
  }, [appliedSearchTerm, categoriaFiltro, precoRange, marcasFiltro, condicaoFiltro, localizacaoFiltro]);

  const ordenarAnuncios = useCallback((anunciosParaOrdenar) => {
    switch (ordenacao) {
      case 'recentes':
        return [...anunciosParaOrdenar].sort((a, b) => new Date(b.dataCriado) - new Date(a.dataCriado));
      case 'precoAsc':
        return [...anunciosParaOrdenar].sort((a, b) => a.preco - b.preco);
      case 'precoDesc':
        return [...anunciosParaOrdenar].sort((a, b) => b.preco - a.preco);
      default:
        return anunciosParaOrdenar;
    }
  }, [ordenacao]);

  const anunciosFiltradosEOrdenados = useMemo(() => {
    const todosAnuncios = [...anunciosDestaque, ...anuncios];
    const anunciosFiltrados = filtrarAnuncios(todosAnuncios);
    const anunciosOrdenados = ordenarAnuncios(anunciosFiltrados);
    
    // Separar anúncios em destaque dos regulares
    const destaques = anunciosOrdenados.filter(a => a.destaque);
    const regulares = anunciosOrdenados.filter(a => !a.destaque);
    
    // Retornar anúncios em destaque primeiro, seguidos pelos regulares
    return [...destaques, ...regulares];
  }, [anunciosDestaque, anuncios, filtrarAnuncios, ordenarAnuncios]);

  const AnuncioSkeleton = () => (
    <div className="bg-white p-4 rounded-lg shadow-md h-full">
      <Skeleton height={200} />
      <Skeleton height={24} width={150} className="mt-4" />
      <Skeleton count={2} className="mt-2" />
      <Skeleton height={24} width={100} className="mt-2" />
    </div>
  );

  const AnuncioCard = ({ anuncio, isDestaque = false }) => (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={`bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition duration-300 ${
        viewMode === 'list' ? 'flex' : 'flex flex-col'
      } ${isDestaque ? 'border-2 border-yellow-400' : ''}`}
    >
      <Link to={`/anuncio/${anuncio.id}`} className={viewMode === 'list' ? 'flex w-full' : 'block'}>
        <div className={`relative bg-white flex items-center justify-center overflow-hidden ${
          viewMode === 'list' ? 'w-32 h-32' : 'h-48'
        }`}>
          <img 
            src={anuncio.imagem} 
            alt={anuncio.titulo} 
            className="max-w-full max-h-full object-contain"
          />
          {isDestaque && (
            <span className="absolute top-1 left-1 bg-yellow-400 text-xs font-semibold px-1 py-0.5 rounded-full">
              Destaque
            </span>
          )}
        </div>
        <div className={`p-2 ${viewMode === 'list' ? 'flex-grow' : ''}`}>
          <div className={viewMode === 'list' ? 'flex justify-between' : ''}>
            <div>
              <h3 className={`font-semibold text-sm mb-0.5 ${isDestaque ? 'text-yellow-600' : 'text-gray-800'}`}>
                {anuncio.titulo}
              </h3>
              <p className="text-xs text-gray-600 mb-0.5">{anuncio.marca}</p>
            </div>
            <p className="text-sm font-bold text-gray-900">R$ {anuncio.preco.toFixed(2)}</p>
          </div>
          <p className="text-xs text-gray-600 mb-1 line-clamp-2">{anuncio.descricao}</p>
          <div className={viewMode === 'list' ? 'flex justify-between items-end' : ''}>
            <p className="text-xs text-gray-500">
              {anuncio.localizacao}
            </p>
            <div className="text-xs text-gray-500">
              {new Date(anuncio.dataCriado).toLocaleDateString()}
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  );

  const handleMarcaChange = (marca) => {
    setMarcasFiltro(prev => 
      prev.includes(marca) 
        ? prev.filter(m => m !== marca) 
        : [...prev, marca]
    );
  };

  useEffect(() => {
    console.log('Anuncios component mounted');
    return () => {
      console.log('Anuncios component unmounted');
    };
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6">Relógios de Pulso</h2>
      {appliedSearchTerm && (
        <p className="mb-4">Resultados da busca para: "{appliedSearchTerm}"</p>
      )}

      <div className="flex flex-col md:flex-row gap-8">
        {/* Coluna de Filtros */}
        <div className="w-full md:w-1/4">
          <div className="bg-white p-4 rounded-lg shadow-md">
            <button
              className="w-full flex justify-between items-center md:hidden"
              onClick={() => setFiltrosExpandidos(!filtrosExpandidos)}
            >
              <h3 className="text-lg font-semibold flex items-center">
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filtros
              </h3>
              {filtrosExpandidos ? (
                <ChevronUpIcon className="w-5 h-5" />
              ) : (
                <ChevronDownIcon className="w-5 h-5" />
              )}
            </button>
            <div className={`md:block ${filtrosExpandidos ? 'block' : 'hidden'}`}>
              {/* Conteúdo dos filtros */}
              <div className="mb-4 mt-4 md:mt-0">
                <label className="block mb-2">Categoria</label>
                <select
                  className="w-full p-2 border rounded"
                  value={categoriaFiltro}
                  onChange={(e) => setCategoriaFiltro(e.target.value)}
                >
                  <option value="">Todas</option>
                  {categoriasDisponiveis.map(categoria => (
                    <option key={categoria} value={categoria}>{categoria}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <h4 className="font-semibold mb-2">Faixa de Preço</h4>
                <Slider
                  range
                  min={precoMinMax[0]}
                  max={precoMinMax[1]}
                  value={precoRange}
                  onChange={(newRange) => setPrecoRange(newRange)}
                  className="mb-4"
                />
                <div className="flex justify-between text-sm text-gray-600">
                  <span>R$ {precoRange[0].toFixed(2)}</span>
                  <span>R$ {precoRange[1].toFixed(2)}</span>
                </div>
              </div>
              <div className="mb-4">
                <h4 className="font-semibold mb-2">Condição</h4>
                <select
                  className="w-full p-2 border rounded"
                  value={condicaoFiltro}
                  onChange={(e) => setCondicaoFiltro(e.target.value)}
                >
                  <option value="">Todas</option>
                  {condicoesDisponiveis.map(condicao => (
                    <option key={condicao} value={condicao}>{condicao}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <h4 className="font-semibold mb-2">Localização</h4>
                <select
                  className="w-full p-2 border rounded"
                  value={localizacaoFiltro}
                  onChange={(e) => setLocalizacaoFiltro(e.target.value)}
                >
                  <option value="">Todas</option>
                  {localizacoesDisponiveis.map(localizacao => (
                    <option key={localizacao} value={localizacao}>{localizacao}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <h4 className="font-semibold mb-2">Marcas</h4>
                {todasMarcas.map(marca => (
                  <label key={marca} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      checked={marcasFiltro.includes(marca)}
                      onChange={() => handleMarcaChange(marca)}
                      className="mr-2"
                    />
                    {marca}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Lista de Anúncios */}
        <div className="w-full md:w-3/4">
          <div className="mb-4 flex justify-between items-center">
            <p className="text-gray-600">{anunciosFiltradosEOrdenados.length} resultados</p>
            <div className="flex items-center">
              <select
                className="mr-4 p-2 border rounded"
                value={ordenacao}
                onChange={(e) => setOrdenacao(e.target.value)}
              >
                <option value="recentes">Mais relevantes</option>
                <option value="precoAsc">Menor preço</option>
                <option value="precoDesc">Maior preço</option>
              </select>
              <button
                onClick={() => setViewMode('grid')}
                className={`p-2 ${viewMode === 'grid' ? 'text-blue-600' : 'text-gray-400'}`}
              >
                <ViewGridIcon className="h-6 w-6" />
              </button>
              <button
                onClick={() => setViewMode('list')}
                className={`p-2 ${viewMode === 'list' ? 'text-blue-600' : 'text-gray-400'}`}
              >
                <ViewListIcon className="h-6 w-6" />
              </button>
            </div>
          </div>

          {loading ? (
            <div className={viewMode === 'grid' ? "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-4" : "space-y-4"}>
              {[...Array(8)].map((_, index) => (
                <AnuncioSkeleton key={index} />
              ))}
            </div>
          ) : (
            <InfiniteScroll
              dataLength={anunciosFiltradosEOrdenados.length}
              next={carregarMaisAnuncios}
              hasMore={hasMore}
              loader={loadingMore && <h4 className="text-center my-4">Carregando mais anúncios...</h4>}
              endMessage={<p className="text-center my-4">Não há mais anúncios para exibir.</p>}
            >
              <AnimatePresence>
                <div className={viewMode === 'grid' 
                  ? "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-4" 
                  : "space-y-4"
                }>
                  {anunciosFiltradosEOrdenados.map((anuncio) => (
                    <AnuncioCard key={anuncio.id} anuncio={anuncio} isDestaque={anuncio.destaque} />
                  ))}
                </div>
              </AnimatePresence>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Anuncios);
