import React, { useContext, useState, useCallback, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { CameraIcon, TagIcon, CurrencyDollarIcon, DocumentTextIcon, MapPinIcon, BuildingStorefrontIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { fetchAnuncioById, atualizarAnuncio } from '../utils/airtable';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

const EditarAnuncio = () => {
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    titulo: '',
    descricao: '',
    preco: '',
    categoria: '',
    marca: '',
    condicao: '',
    localizacao: '',
  });
  const [imagem, setImagem] = useState(null);
  const [previewImagem, setPreviewImagem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!auth || !auth.user) {
      navigate('/login');
    } else {
      carregarAnuncio();
    }
  }, [auth, navigate, id]);

  const carregarAnuncio = async () => {
    try {
      const anuncio = await fetchAnuncioById(id);
      if (anuncio.usuario[0] !== auth.user.id) {
        toast.error('Você não tem permissão para editar este anúncio.');
        navigate('/meus-anuncios');
        return;
      }
      setFormData({
        titulo: anuncio.titulo,
        descricao: anuncio.descricao,
        preco: anuncio.preco.toString(),
        categoria: anuncio.categoria,
        marca: anuncio.marca,
        condicao: anuncio.condicao,
        localizacao: anuncio.localizacao,
      });
      setPreviewImagem(anuncio.imagem);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao carregar anúncio:', error);
      toast.error('Erro ao carregar anúncio. Por favor, tente novamente.');
      navigate('/meus-anuncios');
    }
  };

  const uploadImagem = useCallback(async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const formData = new FormData();
      formData.append('image', compressedFile, compressedFile.name);

      const response = await axios.post('https://api.imgbb.com/1/upload', formData, {
        params: {
          key: process.env.REACT_APP_IMGBB_API_KEY,
        },
      });
      return response.data.data.url;
    } catch (error) {
      console.error('Erro ao comprimir ou fazer upload da imagem:', error);
      throw error;
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleImagemChange = (e) => {
    if (e.target.files[0]) {
      setImagem(e.target.files[0]);
      setPreviewImagem(URL.createObjectURL(e.target.files[0]));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.titulo.trim()) newErrors.titulo = "O título é obrigatório";
    if (!formData.descricao.trim()) newErrors.descricao = "A descrição é obrigatória";
    if (!formData.preco.trim()) newErrors.preco = "O preço é obrigatório";
    if (!formData.categoria) newErrors.categoria = "A categoria é obrigatória";
    if (!formData.marca.trim()) newErrors.marca = "A marca é obrigatória";
    if (!formData.condicao) newErrors.condicao = "A condição é obrigatória";
    if (!formData.localizacao.trim()) newErrors.localizacao = "A localização é obrigatória";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      let imagemUrl = previewImagem;
      if (imagem) {
        imagemUrl = await uploadImagem(imagem);
      }

      const anuncioAtualizado = {
        ...formData,
        preco: parseFloat(formData.preco),
        imagem: imagemUrl,
      };
      await atualizarAnuncio(id, anuncioAtualizado);
      toast.success('Anúncio atualizado com sucesso!');
      navigate('/meus-anuncios');
    } catch (error) {
      console.error('Erro ao atualizar anúncio:', error);
      toast.error('Ocorreu um erro ao atualizar o anúncio. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p className="text-center py-8">Carregando anúncio...</p>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container mx-auto px-4 py-8 max-w-4xl"
    >
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Editar Anúncio</h2>
      <form onSubmit={handleSubmit} className="bg-white shadow-xl rounded-lg overflow-hidden">
        <div className="p-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="titulo" className="block text-sm font-medium text-gray-700 mb-1">
                Título do anúncio
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  name="titulo"
                  id="titulo"
                  className={`block w-full pl-10 pr-3 py-2 sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 ${
                    errors.titulo ? 'border-red-300' : ''
                  }`}
                  placeholder="Ex: Rolex Submariner 2021"
                  value={formData.titulo}
                  onChange={handleChange}
                />
              </div>
              {errors.titulo && <p className="mt-2 text-sm text-red-600">{errors.titulo}</p>}
            </div>

            <div>
              <label htmlFor="preco" className="block text-sm font-medium text-gray-700 mb-1">
                Preço
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <CurrencyDollarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  name="preco"
                  id="preco"
                  className={`block w-full pl-10 pr-3 py-2 sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 ${
                    errors.preco ? 'border-red-300' : ''
                  }`}
                  placeholder="0.00"
                  value={formData.preco}
                  onChange={handleChange}
                />
              </div>
              {errors.preco && <p className="mt-2 text-sm text-red-600">{errors.preco}</p>}
            </div>
          </div>

          <div>
            <label htmlFor="descricao" className="block text-sm font-medium text-gray-700 mb-1">
              Descrição
            </label>
            <div className="mt-1">
              <textarea
                id="descricao"
                name="descricao"
                rows={4}
                className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md ${
                  errors.descricao ? 'border-red-300' : ''
                }`}
                placeholder="Descreva o produto em detalhes"
                value={formData.descricao}
                onChange={handleChange}
              />
            </div>
            {errors.descricao && <p className="mt-2 text-sm text-red-600">{errors.descricao}</p>}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="categoria" className="block text-sm font-medium text-gray-700 mb-1">
                Categoria
              </label>
              <select
                id="categoria"
                name="categoria"
                className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
                  errors.categoria ? 'border-red-300' : ''
                }`}
                value={formData.categoria}
                onChange={handleChange}
              >
                <option value="">Selecione uma categoria</option>
                <option value="Luxo">Luxo</option>
                <option value="Esportivo">Esportivo</option>
                <option value="Vintage">Vintage</option>
                <option value="Smartwatch">Smartwatch</option>
                <option value="Aviador">Aviador</option>
                <option value="Bolso">Bolso</option>
                <option value="Grife">Grife</option>
                <option value="Mergulho">Mergulho</option>
                <option value="Ecológico">Ecológico</option>
                <option value="Militar">Militar</option>
                <option value="Minimalista">Minimalista</option>
                <option value="Skeleton">Skeleton</option>
              </select>
              {errors.categoria && <p className="mt-2 text-sm text-red-600">{errors.categoria}</p>}
            </div>

            <div>
              <label htmlFor="marca" className="block text-sm font-medium text-gray-700 mb-1">
                Marca
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <BuildingStorefrontIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  name="marca"
                  id="marca"
                  className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 pr-3 py-2 sm:text-sm border-gray-300 rounded-md ${
                    errors.marca ? 'border-red-300' : ''
                  }`}
                  value={formData.marca}
                  onChange={handleChange}
                />
              </div>
              {errors.marca && <p className="mt-2 text-sm text-red-600">{errors.marca}</p>}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="condicao" className="block text-sm font-medium text-gray-700 mb-1">
                Condição
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SparklesIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <select
                  id="condicao"
                  name="condicao"
                  className={`mt-1 block w-full pl-10 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
                    errors.condicao ? 'border-red-300' : ''
                  }`}
                  value={formData.condicao}
                  onChange={handleChange}
                >
                  <option value="">Selecione a condição</option>
                  <option value="Novo">Novo</option>
                  <option value="Usado">Usado</option>
                  <option value="Recondicionado">Recondicionado</option>
                </select>
              </div>
              {errors.condicao && <p className="mt-2 text-sm text-red-600">{errors.condicao}</p>}
            </div>

            <div>
              <label htmlFor="localizacao" className="block text-sm font-medium text-gray-700 mb-1">
                Localização
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  name="localizacao"
                  id="localizacao"
                  className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 pr-3 py-2 sm:text-sm border-gray-300 rounded-md ${
                    errors.localizacao ? 'border-red-300' : ''
                  }`}
                  value={formData.localizacao}
                  onChange={handleChange}
                />
              </div>
              {errors.localizacao && <p className="mt-2 text-sm text-red-600">{errors.localizacao}</p>}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Imagem do anúncio</label>
            <div className="mt-1 flex items-center">
              {previewImagem && (
                <img src={previewImagem} alt="Preview" className="w-32 h-32 object-cover rounded-md mr-4" />
              )}
              <label htmlFor="imagem-upload" className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span>Alterar imagem</span>
                <input id="imagem-upload" name="imagem-upload" type="file" className="sr-only" onChange={handleImagemChange} accept="image/*" />
              </label>
            </div>
          </div>
        </div>

        <div className="px-6 py-3 bg-gray-50 text-right">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={loading}
          >
            {loading ? 'Atualizando...' : 'Atualizar Anúncio'}
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default EditarAnuncio;