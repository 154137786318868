import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { fetchFavoritos, removerFavorito } from '../utils/airtable';
import { HeartIcon, TrashIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

const Favoritos = () => {
  const { user } = useContext(AuthContext);
  const [favoritos, setFavoritos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const carregarFavoritos = async () => {
      if (user) {
        try {
          setLoading(true);
          const favoritosUsuario = await fetchFavoritos(user.id);
          setFavoritos(favoritosUsuario);
        } catch (error) {
          console.error('Erro ao carregar favoritos:', error);
          toast.error('Erro ao carregar favoritos. Por favor, tente novamente.');
        } finally {
          setLoading(false);
        }
      }
    };
    carregarFavoritos();
  }, [user]);

  const handleRemoverFavorito = async (anuncioId) => {
    try {
      await removerFavorito(user.id, anuncioId);
      setFavoritos(favoritos.filter(fav => fav.id !== anuncioId));
      toast.success('Anúncio removido dos favoritos.');
    } catch (error) {
      console.error('Erro ao remover favorito:', error);
      toast.error('Erro ao remover favorito. Por favor, tente novamente.');
    }
  };

  if (loading) {
    return <p className="text-center py-8">Carregando seus favoritos...</p>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container mx-auto px-4 py-8 max-w-4xl"
    >
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Meus Favoritos</h2>
      {favoritos.length === 0 ? (
        <p className="text-center text-gray-500">Você ainda não tem anúncios favoritos.</p>
      ) : (
        <ul className="space-y-4">
          {favoritos.map((favorito) => (
            <li key={favorito.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="flex items-center p-4">
                <img src={favorito.imagem} alt={favorito.titulo} className="w-24 h-24 object-cover rounded-md mr-4" />
                <div className="flex-grow">
                  <h3 className="text-lg font-semibold">{favorito.titulo}</h3>
                  <p className="text-gray-600 text-sm mb-2">{favorito.descricao.substring(0, 100)}...</p>
                  <p className="text-xl font-bold text-green-600">R$ {favorito.preco.toFixed(2)}</p>
                </div>
                <div className="flex flex-col items-center space-y-2">
                  <Link to={`/anuncio/${favorito.id}`} className="text-blue-600 hover:text-blue-800">
                    Ver detalhes
                  </Link>
                  <button
                    onClick={() => handleRemoverFavorito(favorito.id)}
                    className="flex items-center text-red-600 hover:text-red-800"
                  >
                    <TrashIcon className="w-5 h-5 mr-1" />
                    Remover
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </motion.div>
  );
};

export default Favoritos;
