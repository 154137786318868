import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import { FcGoogle } from 'react-icons/fc';
import { ReactComponent as Logo } from './logotimespot.svg';
import { XMarkIcon, ClockIcon } from '@heroicons/react/24/outline';

// Função de debug
const debug = (message) => {
  console.log(`[DEBUG] ${new Date().toISOString()}: ${message}`);
};

const Login = () => {
  const { loginWithGoogle } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    debug(`Ambiente atual: ${process.env.NODE_ENV}`);
    debug(`REACT_APP_GOOGLE_CLIENT_ID: ${process.env.REACT_APP_GOOGLE_CLIENT_ID ? 'Definido' : 'Não definido'}`);
  }, []);

  const handleGoogleLogin = async () => {
    try {
      const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
      debug(`Client ID utilizado: ${clientId}`);

      const redirectUri = process.env.NODE_ENV === 'production'
        ? 'https://www.timespot.com.br/auth/google'
        : 'http://localhost:3000/auth/google';
      debug(`Redirect URI: ${redirectUri}`);

      const scope = 'email profile';
      debug(`Escopo: ${scope}`);

      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}&prompt=consent`;
      
      debug(`URL de autenticação completa: ${authUrl}`);
      
      // Adicione um pequeno atraso antes do redirecionamento para garantir que os logs sejam exibidos
      setTimeout(() => {
        debug('Redirecionando para a URL de autenticação do Google...');
        window.location.href = authUrl;
      }, 100);
    } catch (error) {
      debug(`Erro ao iniciar login com Google: ${error.message}`);
      console.error('Stack trace do erro:', error.stack);
    }
  };

  const handleClose = () => {
    debug('Botão de fechar clicado. Navegando para a página inicial.');
    navigate('/');
  };

  debug('Componente Login renderizado');

  return (
    <div className="min-h-screen flex flex-col lg:flex-row">
      <div className="hidden lg:flex w-1/2 bg-gradient-to-br from-blue-900 via-purple-900 to-indigo-900 justify-center items-center relative">
        <div className="absolute inset-0 bg-black opacity-30"></div>
        <div className="relative z-10 max-w-2xl text-white p-12">
          <Logo className="mx-auto h-24 w-auto mb-12" />
          <h1 className="text-6xl font-extrabold mb-8 leading-tight">
            Descubra o Tempo no seu Pulso
          </h1>
          <p className="text-2xl mb-12 leading-relaxed">
            Entre para a comunidade TimeSpot e explore um mundo de relógios exclusivos, 
            conecte-se com entusiastas e gerencie suas coleções com estilo.
          </p>
          <div className="grid grid-cols-2 gap-8">
            {['Coleções Únicas', 'Comunidade Vibrante', 'Gerenciamento Inteligente', 'Tendências Atualizadas'].map((feature, index) => (
              <div key={index} className="flex items-center">
                <ClockIcon className="h-8 w-8 mr-4 text-blue-300" />
                <span className="text-lg font-semibold">{feature}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex flex-col justify-center items-center bg-white p-8">
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors duration-300"
          aria-label="Fechar"
        >
          <XMarkIcon className="h-8 w-8" />
        </button>
        <div className="w-full max-w-md">
          <Logo className="mx-auto h-20 w-auto text-blue-600 lg:hidden mb-8" />
          <h2 className="text-center text-4xl font-extrabold text-gray-900 mb-2">
            Bem-vindo de volta
          </h2>
          <p className="text-center text-xl text-gray-600 mb-12">
            Sua jornada no tempo continua aqui
          </p>
          <button
            onClick={handleGoogleLogin}
            className="w-full flex justify-center items-center py-4 px-6 border border-transparent text-lg font-medium rounded-full text-white bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg transition-all duration-300 ease-in-out"
          >
            <FcGoogle className="h-6 w-6 mr-3" />
            Continuar com Google
          </button>
          <div className="mt-8">
            <p className="text-center text-sm text-gray-500">
              Ao fazer login, você concorda com nossos{' '}
              <a href="#" className="font-medium text-blue-600 hover:text-blue-500 transition-colors duration-300">
                Termos de Serviço
              </a>{' '}
              e{' '}
              <a href="#" className="font-medium text-blue-600 hover:text-blue-500 transition-colors duration-300">
                Política de Privacidade
              </a>
            </p>
          </div>
        </div>
        <div className="mt-8 text-center text-sm text-gray-500">
          <p>© 2023 TimeSpot. Todos os direitos reservados.</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
